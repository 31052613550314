import React, { useState, useEffect } from 'react';
// import Layout from '@/common/Layout';
import { Form, Card, Space, Input, Tag } from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import { getAllRequisitionSlips } from '../../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import Layout from '@/common/Layout';
import { useDispatch } from 'react-redux';
import { getLoggedInUser } from 'utilities/helpers';
import { EyeOutlined } from '@ant-design/icons';

const { Search } = Input;

const RequisitionList = ({ loading }) => {
  const [form] = Form.useForm();
  const { user } = getLoggedInUser();
  // const { SuperadminProddata } = getRoles('Production');
  //   const { allSamples } = useSelector((state) => state.stocks);
  const [allsuppliers, setAllsuppliers] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({});
  const [search, setSearch] = useState('');
  //   console.log(allsamples);
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetAllRequisitions();
  }, [page, search]);

  const handleGetAllRequisitions = () => {
    const payload = { page: page, search: search };
    dispatch(getAllRequisitionSlips(payload)).then((response) => {
      if (response?.payload?.success) {
        setAllsuppliers(response?.payload?.data);
        setPagination(response?.payload?.pagination);
      }
    });
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'product_name',
      key: 'product_name',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
      key: 'stage',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'BMR/BPR Number',
      dataIndex: 'bmr_bpr_no',
      key: 'bmr_bpr_no',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Batch Size',
      dataIndex: 'batch_size',
      key: 'batch_size',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'No.of Batches',
      dataIndex: 'no_of_batches',
      key: 'no_of_batches',
      ellipsis: true,
      width: '100px'
    },
    {
      title: 'Status',
      dataIndex: 'sub_product_type',
      key: 'sub_product_type',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return (
          <Tag
            color={
              record?.status === 'Pending'
                ? '#55acee'
                : record?.status === 'Approved'
                ? '#87d068'
                : record?.status === 'Rejected'
                ? '#f50'
                : '#cd201f'
            }
          >
            {record?.status}
          </Tag>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'sub_product_type',
      key: 'sub_product_type',
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        return (
          <Space>
            <a href={`/product-requisition-view/${record.id}`}>
              <EyeOutlined />
            </a>
            {/* {user?.role == 'prod_user' || user?.role == 'qa_super_user' ? (
              <a href={`/product-requisition-edit/${record.id}`}>
                <EditOutlined />
              </a>
            ) : (
              ''
            )} */}
          </Space>
        );
      }
    }
  ];

  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical">
          <Card
            title={<span>Requisition Details</span>}
            className={`${styles.cardStyles_approver}`}
            extra={
              <Space>
                {user?.role == 'prod_user' && user?.level === 2 ? (
                  <CustomButton
                    className={styles.inwardButton}
                    // onClick={() => history.push('/create-product')}
                    onClick={() => history.push('/product-requisition-create')}
                    type="primary"
                  >
                    Create
                  </CustomButton>
                ) : (
                  ''
                )}
                <Search
                  onChange={(e) => handleChange(e)}
                  className={`w-100 ${styles.searchbar}`}
                  justify="start"
                  align="start"
                  placeholder="Search by Product Name"
                />
              </Space>
            }
          >
            <div className={`${styles.table_container}`}>
              <CustomTable
                columns={columns}
                pagination={{
                  size: 'small',
                  total: pagination?.total_records || 0,
                  showSizeChanger: false,
                  onChange: (page) => {
                    setPage(page);
                  }
                }}
                dataSource={allsuppliers}
                loading={loading}
              />
            </div>
          </Card>
          <br />
          <FormItem className="text-center">
            <CustomButton
              className={styles.inwardButton}
              onClick={() => history.push('/dashboard')}
              type="primary"
            >
              Back to Home
            </CustomButton>
          </FormItem>
        </Form>
      </Layout>
    </>
  );
};
export default RequisitionList;
