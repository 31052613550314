import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import { Image, Space, Row, Col, Form, Card } from 'antd';
import { getMetricsMasters } from '@/features/blenderDashboard/redux/slice';
import { useDispatch } from 'react-redux';
import Layout from '@/common/Layout';
import MetricCard from '@/images/Blender.png';
import CoatingMechine from '@/images/coating_machine.png';
import FBP from '@/images/FBP.png';
import Granulator from '@/images/Granulator.png';
// import comingSoon from '@/images/coming_soon.png';

const LandingPage = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    handleGetEquiplents();
  }, []);

  const handleGetEquiplents = () => {
    dispatch(getMetricsMasters()).then((response) => {
      setEquipments(response?.payload?.data);
    });
  };

  const [equipments, setEquipments] = useState([]);
  const cards = equipments?.map((item) => {
    return {
      title: item.eqp_name,
      icon:
        item.eqp_name === 'Blending'
          ? MetricCard
          : item?.eqp_name === 'FBP'
          ? FBP
          : item?.eqp_name === 'Granulation'
          ? Granulator
          : CoatingMechine,
      url: `blender-dashboard?id=${item.asset_id}`,
      eqp_area_masters: item.eqp_area_masters
    };
  });
  return (
    <>
      <Layout sider={false}>
        <Form form={form} layout="vertical">
          {cards.map((items, index) => {
            return (
              <>
                <Card
                  title={items?.title}
                  key={index}
                  className={styles.cardStyles_approver}
                >
                  <Row gutter={24}>
                    {items.eqp_area_masters.map((item, index) => {
                      return (
                        <Col
                          xs={12}
                          xl={4}
                          md={6}
                          sm={6}
                          span={6}
                          className={`${styles.cardbody1} mb-2`}
                          key={index}
                        >
                          <Card
                            hoverable
                            className={styles.cardbody2}
                            onClick={(e) => {
                              e.preventDefault();
                              window.location.href = item?.is_coming
                                ? '#'
                                : items.url;
                            }}
                          >
                            <Space direction="vertical">
                              {item?.is_coming ? (
                                <div className={styles.ribbon}>
                                  <span>Coming Soon</span>
                                </div>
                              ) : (
                                <div className={styles.ribbon1}>
                                  <span></span>
                                </div>
                              )}
                              <Image
                                src={items.icon}
                                preview={false}
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = item?.is_coming
                                    ? '#'
                                    : `${items.url}`;
                                }}
                                style={{ cursor: 'pointer' }}
                                className={styles.stickers}
                              />
                              <span>{item.eqp_area_name}</span>
                            </Space>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Card>
                <br />
              </>
            );
          })}
          {/* <Row gutter={24}>
            {cards.map((items, index) => {
              return (
                <Col
                  xs={6}
                  xl={4}
                  md={6}
                  sm={6}
                  span={6}
                  className={`${styles.cardbody1} mb-2`}
                  key={index}
                >
                  <Card
                    className={styles.cardStyles}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = `${items.url}`;
                    }}
                  >
                    <Space>
                      <Image
                        src={items.icon}
                        preview={false}
                        style={{ cursor: 'pointer' }}
                        className={styles.cardSticker}
                        // className={
                        //   user?.role === 'qc_initiator'
                        //     ? styles.qc_stickers
                        //     : styles.stickers
                        // }
                      />
                      <span>{items.title}</span>
                    </Space>
                  </Card>
                </Col>
              );
            })}
          </Row> */}
        </Form>
      </Layout>
    </>
  );
};

export default LandingPage;
