import React, { useEffect, useState } from 'react';
import Layout from '@/common/Layout';
import { useDispatch } from 'react-redux';
import { createCategory, getActivities } from '../redux/slice';
import { Card, Col, DatePicker, Form, Row, Input, Space, Select } from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
const MastersPage = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    handleGetActivities();
  }, []);

  const handleGetActivities = () => {
    dispatch(getActivities()).then((res) => {
      if (res.payload.success) {
        setData(res.payload.data);
      }
    });
  };

  const onFinish = (values) => {
    const date = values['date_planned']
      ? moment(values['date_planned']._d)
      : '';
    date !== '' ? (values['date_planned'] = date) : '';
    dispatch(createCategory(values)).then((res) => {
      if (res.payload.success) {
        history.push('/activity-tracker-list');
      } else {
        console.log('Error fetching categories');
      }
    });
  };

  return (
    <Layout sider={false}>
      <Card title="Create Category" className={`${styles.cardStyles_approver}`}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={8}>
              <FormItem
                label="Category"
                name="category_id"
                rules={[
                  {
                    required: true,
                    message: 'Select Category'
                  }
                ]}
                className={styles.customLable}
              >
                <Select placeholder="Select Category">
                  {data?.map((category, index) => {
                    return (
                      <option value={category?.id} key={index}>
                        {category?.category}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Equipment ID"
                name="equipment_id"
                rules={[
                  {
                    required: true,
                    message: 'Enter equipment id'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Equipment ID" />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Equipment Name"
                name="equipment_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter equipment name'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter Equipment Name" />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Maintenance Frequency"
                name="maintainence_frequency"
                rules={[
                  {
                    required: true,
                    message: 'Selected Maintenance Frequency'
                  }
                ]}
                className={styles.customLable}
              >
                <Select>
                  <option value="Daily">Daily</option>
                  <option value="Monthly">Monthly</option>
                  <option value="Quarterly">Quarterly</option>
                  <option value="Yearly">Yearly</option>
                </Select>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Date Planned"
                name="date_planned"
                rules={[
                  {
                    required: true,
                    message: 'Enter equipment name'
                  }
                ]}
                className={styles.customLable}
              >
                <DatePicker
                  placeholder="Select Planned date"
                  style={{ width: '100%' }}
                />
              </FormItem>
            </Col>
          </Row>
          <FormItem className="text-center">
            <Space>
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Submit
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Card>
    </Layout>
  );
};
export default MastersPage;
