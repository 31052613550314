import React from 'react';
import Layout from '@/common/Layout';
import ProductionListPage from './components/ProductionListPage';

const productionLabelGen = () => {
  return (
    <>
      <Layout sider={false}>
        <ProductionListPage />
      </Layout>
    </>
  );
};

export default productionLabelGen;
