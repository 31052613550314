import React, { useEffect } from 'react';
import styles from '@/common/Layout/index.module.less';
import { Card, Empty, Input, Tag, Space } from 'antd';
import CustomTable from 'common/Content/CustomTable';
// import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllInvoicesList } from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import { history } from '@/app/history';
import { getLoggedInUser } from 'utilities/helpers';

const { Search } = Input;
const InvoiceTrackerListPage = () => {
  const { allInvoices, pagination, getAllMaterilLoading } = useSelector(
    (state) => state.invoiceTracker
  );
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();

  const user = getLoggedInUser();
  useEffect(() => {
    handleGetAllInvoices();
  }, [page, search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleGetAllInvoices = () => {
    let payload = {
      search: search,
      page
    };
    dispatch(getAllInvoicesList(payload));
  };

  const columns = [
    {
      title: 'PO No',
      dataIndex: 'po_no',
      key: 'po_no',
      render: (_, record) => {
        {
          return <a href={`/view-inv-tracker/${record.id}`}>{record?.po_no}</a>;
        }
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'PO Date',
      dataIndex: 'po_date',
      key: 'po_date',
      ellipsis: true,
      render: (text) => {
        return text;
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Vendor Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: (_, record) => {
        {
          return record?.supplier?.name;
        }
      },
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },

    {
      title: 'Inv No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Inv Date',
      dataIndex: 'invoice_date',
      key: 'invoice_date',
      align: 'center',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Inv Rec Date',
      dataIndex: 'invoice_recieved_date',
      key: 'invoice_recieved_date',
      align: 'center',
      ellipsis: true,
      // render: (mrn_type) => (
      //   <>
      //     <Tag color={mrn_type == 'Update MRN' ? '#2db7f5' : 'green'}>
      //       {mrn_type || 'General MRN'}
      //     </Tag>
      //   </>
      // ),
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Gate Entry No',
      dataIndex: 'gate_entryno',
      key: 'gate_entryno',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Gate Entry Date',
      dataIndex: 'gate_entry_date',
      key: 'gate_entry_date',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Current Stage',
      dataIndex: 'current_stage',
      key: 'current_stage',
      align: 'center',
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (current_stage) => (
        <>
          <Tag color={current_stage !== 'approved' ? 'grey' : 'green'}>
            {current_stage ? current_stage?.toUpperCase() : 'PENDING'}
          </Tag>
        </>
      )
    }
  ];
  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };
  return (
    <>
      <Card
        className={`${styles.cardStyles_approver}`}
        title="GATE ENTRY"
        extra={
          <Space>
            {user?.user?.role === 'gate_entry' && (
              <CustomButton
                type="primary"
                onClick={() => history.push('/create-inv-tracker')}
              >
                New Gate Entry
              </CustomButton>
            )}
            <Search
              onChange={(e) => handleChange(e)}
              className={`w-100 ${styles.searchbar}`}
              justify="start"
              align="start"
              placeholder="Search by Invoice no./Supplier name"
            />
          </Space>
        }
      >
        <div className={`${styles.table_container}`}>
          <CustomTable
            locale={locale}
            loading={getAllMaterilLoading}
            columns={columns}
            dataSource={allInvoices}
            scroll={{ x: 'auto' }}
            pagination={{
              size: 'small',
              total: pagination?.total_records || 0,
              showSizeChanger: false,
              onChange: (page) => {
                setPage(page);
              }
            }}
          />
        </div>
      </Card>
    </>
  );
};

export default InvoiceTrackerListPage;
