import React, { useEffect } from 'react';
import { Row, Col, Select, Card, Empty, Form, message, Space } from 'antd';
import styles from '@/common/Layout/index.module.less';
import Layout from '@/common/Layout';
import { useState } from 'react';
import CustomTable from '@/common/Content/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllFreeContainers,
  getContainers,
  updatebinposition
} from '../../redux/slice';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import FormItem from '@/common/FormItem';
import { QrcodeOutlined, DeleteOutlined } from '@ant-design/icons';
import { QrReader } from 'react-qr-reader';
import { getLoggedInUser } from 'utilities/helpers';
const binfreeContainersPage = () => {
  const { allFreeContainers, pagination, getAllFreeContainerLoading } =
    useSelector((state) => state.bins);
  console.log(allFreeContainers);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [bin, setBin] = useState('');
  const [binContainers, setBinContainers] = useState([]);
  const [openCam, setOpenCam] = useState(false);
  const [search, setSearch] = useState('');
  const [bincard, setBincard] = useState(false);

  console.log(search);
  const { user } = getLoggedInUser();

  useEffect(() => {
    handleGetAllFreeContainers();
  }, [page]);
  const handleGetAllFreeContainers = () => {
    let payload = {
      page
    };
    dispatch(getAllFreeContainers(payload));
  };

  const handleError = (err) => {
    console.error(err);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  const onChangebin = (values) => {
    setBin(values);
    form.resetFields();
  };

  const handleQrScan = async (scanData) => {
    // scanData.stopPropagation();
    if (scanData && scanData !== '') {
      scanData.text
        .slice(scanData.text.lastIndexOf('/') + 1)
        .substring(0, 3) === 'BIN'
        ? setBin(scanData.text.slice(scanData.text.lastIndexOf('/') + 1))
        : message.error('This scanned Bin  is not valid');
      setOpenCam(false);
    }
  };

  const onSearchbin = (value) => {
    if (value?.length > 4) {
      setSearch(value);
      const payload = { id: value };
      dispatch(getContainers(payload)).then(function (response) {
        if (response?.payload?.success) {
          console.log(response?.payload?.data);
          setBinContainers(response?.payload?.data);
        }
      });
    }
  };

  const onFinish = (values) => {
    console.log(values);
    values['batch_numbers'] = selectedRowKeys;
    values['bin_no'] = bin;
    dispatch(updatebinposition(values)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response?.payload?.data);
        form.resetFields();
        setBin('');
        handleGetAllFreeContainers();
      }
    });
  };

  const columns =
    user?.role === 'prod_user'
      ? [
          {
            title: 'Product Batch number',
            dataIndex: 'batch_no',
            // key: 'id',
            ellipsis: true,
            width: '100px',
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          },
          {
            title: 'Product Name',
            dataIndex: 'name',
            // key: 'id',
            ellipsis: true,
            width: '100px',
            sorter: (a, b) =>
              a?.material?.name?.localeCompare(b?.material?.name),
            render: (text, record) => {
              return record?.product_detail?.name;
            },
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          },
          {
            title: 'Current Location',
            dataIndex: 'prod_bin_status',
            // key: 'id',
            ellipsis: true,
            width: '100px',
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          },
          {
            title: 'Date of Receipts',
            dataIndex: 'createdAt',
            // key: 'id',
            align: 'center',
            width: '100px',
            sorter: (a, b) =>
              moment(a[`receipt_date`]).unix() -
              moment(b[`receipt_date`]).unix(),
            render: (createdAt) => (
              <>{moment(createdAt).format('DD MMM YYYY')}</>
            ),
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          }
        ]
      : [
          {
            title: 'Inhouse Batch number',
            dataIndex: 'inhouse_batch_number',
            // key: 'id',
            ellipsis: true,
            width: '100px',
            sorter: (a, b) =>
              a?.inhouse_batch_number?.localeCompare(b?.inhouse_batch_number),
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          },
          {
            title: 'Material Name',
            dataIndex: 'document_verification',
            // key: 'id',
            ellipsis: true,
            width: '100px',
            sorter: (a, b) =>
              a?.material?.name?.localeCompare(b?.material?.name),
            render: (text, record) => {
              return record?.material?.name;
            },
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          },
          {
            title: 'Current Location',
            dataIndex: 'current_bin_status',
            // key: 'id',
            ellipsis: true,
            width: '100px',
            sorter: (a, b) =>
              a?.current_bin_status?.localeCompare(b?.current_bin_status),
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          },
          {
            title: 'Date of Receipts',
            dataIndex: 'createdAt',
            // key: 'id',
            align: 'center',
            width: '100px',
            sorter: (a, b) =>
              moment(a[`receipt_date`]).unix() -
              moment(b[`receipt_date`]).unix(),
            render: (createdAt) => (
              <>{moment(createdAt).format('DD MMM YYYY')}</>
            ),
            responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
          }
        ];
  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };
  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card
          className={`${styles.cardStyles_approver}`}
          title={<span>BIN FREE CONTAINERS</span>}
        >
          <div className={`${styles.table_container}`}>
            <CustomTable
              locale={locale}
              loading={getAllFreeContainerLoading}
              columns={columns}
              dataSource={allFreeContainers}
              pagination={{
                size: 'small',
                total: pagination?.total_records || 0,
                showSizeChanger: false,
                onChange: (page) => {
                  setPage(page);
                }
              }}
              rowSelection={rowSelection}
              rowKey={(record) => record.id}
              // className={styles.scrolling}
              scroll={{ x: 'auto' }}
            />
          </div>
          <FormItem className="text-center">
            <CustomButton type="primary" onClick={() => setBincard(true)}>
              Assign to Bin
            </CustomButton>
          </FormItem>
        </Card>
        {bincard ? (
          <Card className={`${styles.cardStyles_approver1}`}>
            <Row gutter={24}>
              <Col xs={12} xl={16} span={16} style={{ paddingTop: '11px' }}>
                <h6 className={styles.lable}>Scan or search bin</h6>
              </Col>
              {bin == '' ? (
                <>
                  <Col xs={10} xl={6} span={6}>
                    <FormItem
                      name="bin_containers"
                      rules={[
                        {
                          required: false,
                          message: 'Enter damaged containers'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        placeholder="Scan or select bin"
                        optionFilterProp="children"
                        onChange={onChangebin}
                        onSearch={onSearchbin}
                      >
                        {binContainers.map((item, index) => {
                          return (
                            <option value={item.bin_number} key={index}>
                              {item.bin_number}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                  <Col xs={2} xl={2} span={2} md={2} sm={2}>
                    <CustomButton
                      onClick={() => setOpenCam(true)}
                      className={styles.qr}
                    >
                      <QrcodeOutlined onClick={() => setOpenCam(true)} />
                    </CustomButton>
                  </Col>
                </>
              ) : (
                <>
                  <Col xs={10} xl={6} span={6}>
                    <h6 className={styles.lableValue}>{bin}</h6>
                  </Col>
                  <Col xs={2} xl={2} span={2}>
                    <DeleteOutlined
                      onClick={() => setBin('')}
                      className={styles.removal}
                    />
                  </Col>
                </>
              )}
            </Row>
            <br />
            <FormItem className="text-center">
              <Space size={5}>
                <CustomButton type="primary" onClick={() => history.back()}>
                  Back
                </CustomButton>
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  disabled={bin ? false : true}
                >
                  Update Bin
                </CustomButton>
              </Space>
            </FormItem>
          </Card>
        ) : (
          ''
        )}
      </Form>
      {openCam ? (
        <QrReader
          constraints={{ facingMode: 'environment' }}
          delay={1000}
          onError={handleError}
          onResult={(result) => handleQrScan(result)}
          // chooseDeviceId={()=>selected}
          style={{ width: '3px', height: '3px' }}
        />
      ) : (
        ''
      )}
    </Layout>
  );
};
export default binfreeContainersPage;
