import React from 'react';
import Layout from '@/common/Layout';
import InvoiceTrackerListPage from './components/InvoiceTrackerListPage';

const InvoiceTracker = () => {
  return (
    <>
      <Layout sider={false}>
        <InvoiceTrackerListPage />
      </Layout>
    </>
  );
};

export default InvoiceTracker;
