import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get_metrics, get_metricsMasters, get_metrics_bacthes } from './api';

const initialState = {
  loading: false
};

const actions = {
  GET_METRICS: 'getMetrics/GET_METRICS',
  GET_METRICS_MASTERS: 'getMetrics/GET_METRICS_MASTERS',
  GET_ALL_BATCHES: 'getAllBatches/GET_ALL_BATCHES'
};

export const getMetrics = createAsyncThunk(
  actions.GET_METRICS,
  async (payload) => {
    const response = await get_metrics('eqp_dashboard', payload);
    return response;
  }
);

export const getMetricsMasters = createAsyncThunk(
  actions.GET_METRICS_MASTERS,
  async (payload) => {
    const response = await get_metricsMasters(
      'eqp_integration_master',
      payload
    );
    return response;
  }
);

export const getAllBatches = createAsyncThunk(
  actions.GET_ALL_BATCHES,
  async (payload) => {
    const response = await get_metrics_bacthes('get_blender_batch', payload);
    return response;
  }
);

export const matricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMetrics.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getMetrics.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getMetrics.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getMetricsMasters.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getMetricsMasters.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getMetricsMasters.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllBatches.pending, (state) => {
        state.getAllApproverLoading = true;
      })
      .addCase(getAllBatches.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getAllBatches.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllApproverLoading = false;

        message.error(msg);
      });
  }
});
export default matricsSlice.reducer;
