import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get_products, createProduct, deleteProductId } from './api';

const initialState = {
  loading: false
};

const actions = {
  GET_PRODUCTS: 'getProducts/GET_PRODUCTS',
  GET_CATEGORIES_BY_ID: 'getCategoriesById/GET_CATEGORIES_BY_ID',
  CREATE_EQ_TRACKER: 'createEqTracker/CREATE_EQ_TRACKER',
  GET_PRODUCT_BATCHES: 'getProductBatches/GET_PRODUCT_BATCHES',
  GET_PROCESS_GROUPS: 'getProcessGroups/GET_PROCESS_GROUPS',
  CREATE_PRODUCT_DETAILS: 'createProducts/CREATE_PRODUCT_DETAILS',
  DELETE_PRODUCT: 'deleteProduct/DELETE_PRODUCT'
};

export const getProducts = createAsyncThunk(
  actions.GET_PRODUCTS,
  async (payload) => {
    const response = await get_products('get_prod_dispenses', payload);
    return response;
  }
);

// export const getCategoriesById = createAsyncThunk(
//   actions.GET_CATEGORIES_BY_ID,
//   async (payload) => {
//     const response = await get_activities('get', payload);
//     return response;
//   }
// );

export const getProductBatches = createAsyncThunk(
  actions.GET_PRODUCT_BATCHES,
  async (payload) => {
    const response = await get_products('search_produch_batch', payload);
    return response;
  }
);

export const getProcessGroups = createAsyncThunk(
  actions.GET_PROCESS_GROUPS,
  async (payload) => {
    const response = await get_products('search_process_group', payload);
    return response;
  }
);

export const createProducts = createAsyncThunk(
  actions.CREATE_PRODUCT_DETAILS,
  async (payload) => {
    const response = await createProduct('create_prod_dispense', payload);
    return response;
  }
);

export const deleteProduct = createAsyncThunk(
  actions.DELETE_PRODUCT,
  async (payload) => {
    const response = await deleteProductId('delete_prod_dispenses', payload);
    return response;
  }
);

export const matricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getProducts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    // builder
    //   .addCase(getCategoriesById.pending, (state) => {
    //     state.loading = true;
    //   })
    //   .addCase(getCategoriesById.fulfilled, (state, action) => {
    //     const { success, message: msg } = action.payload;
    //     state.loading = false;
    //     if (!success) {
    //       message.error(msg);
    //     }
    //   })
    //   .addCase(getCategoriesById.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.loading = false;

    //     message.error(msg);
    //   });

    builder
      .addCase(getProductBatches.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductBatches.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getProductBatches.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getProcessGroups.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProcessGroups.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getProcessGroups.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProducts.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createProducts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});
export default matricsSlice.reducer;
