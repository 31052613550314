import React, { useEffect, useState } from 'react';
import {
  Card,
  Tabs,
  Empty,
  Tag,
  Space,
  Input,
  DatePicker,
  Dropdown,
  Menu
} from 'antd';
import styles from '@/common/Layout/index.module.less';
import { useDispatch } from 'react-redux';
import { getActivities, getCategoriesById } from '../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import { EyeOutlined, FilterOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

const { RangePicker } = DatePicker;

const TrackerListPage = ({ loading }) => {
  const [data, setData] = useState([]);
  const [categorydata, setCategorydata] = useState([]);
  const [pagination, setPagination] = useState();
  const [page, setPage] = useState(1);
  const [category_id, setCategory_id] = useState(1);
  const [search, setSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([
    'Critical',
    'Priority',
    'Paused',
    'Completed'
  ]);

  const dispatch = useDispatch();
  useEffect(() => {
    handleGetActivities();
  }, []);

  useEffect(() => {
    handleGetCategoriesById();
  }, [category_id, search, page, searchText, dateRange]);

  const handleGetActivities = () => {
    dispatch(getActivities()).then((res) => {
      if (res.payload.success) {
        setData(res.payload.data);
        setCategory_id(res.payload.data[0].id);
      }
    });
  };

  const handleMenuClick = (e) => {
    setSearchText(e.key.toLowerCase()); // Optionally set the selected item as the input value
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {filteredOptions.map((option) => (
        <Menu.Item key={option}>{option}</Menu.Item>
      ))}
    </Menu>
  );

  const handleGetCategoriesById = () => {
    const from_date = dateRange.length
      ? moment(dateRange[0]._d).format('YYYY-MM-DD')
      : '';
    const to_date = dateRange.length
      ? moment(dateRange[1]._d).format('YYYY-MM-DD')
      : '';
    const payload = {
      category_id: category_id,
      page,
      search,
      status: searchText,
      from_date,
      to_date
    };
    dispatch(getCategoriesById(payload)).then((res) => {
      if (res.payload.success) {
        setCategorydata(res.payload.data);
        setPagination(res.payload.pagination);
        console.log(res.payload.data);
      } else {
        console.log('Error fetching categories');
      }
    });
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    // Filter options based on the search text
    const filtered = ['Critical', 'Priority', 'Paused', 'Completed'].filter(
      (option) => option.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredOptions(filtered);
  };

  const callback = (key) => {
    console.log(key);
    setCategory_id(key);
  };

  const handleDateChange = (e) => {
    console.log(e);
    setDateRange(e);
  };
  const columns = [
    {
      title: 'Equipment ID',
      dataIndex: 'equipment_id',
      key: 'equipment_id',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Equipment Name',
      dataIndex: 'equipment_name',
      key: 'equipment_name',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Frequency',
      dataIndex: 'maintainence_frequency',
      key: 'maintainence_frequency',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Date Planned',
      dataIndex: 'date_planned',
      key: 'date_planned',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (text, record) => {
        {
          return record?.status === 'critical' ? (
            <Tag color="red">{text.toUpperCase()}</Tag>
          ) : record?.status === 'completed' ? (
            <Tag color="green">{text.toUpperCase()}</Tag>
          ) : record?.status === 'priority' ? (
            <Tag color="orange">{text.toUpperCase()}</Tag>
          ) : record?.status === 'paused' ? (
            <Tag color="purple">{text.toUpperCase()}</Tag>
          ) : (
            <Tag color="blue">{text.toUpperCase()}</Tag>
          );
        }
      }
    },
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        {
          return (
            <a href={`/view-tracker/${record.id}`}>
              <EyeOutlined />
            </a>
          );
        }
      }
    }
  ];
  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  return (
    <Card
      title="PMP Maintanance Activity Tracker"
      className={`${styles.cardStyles_approver}`}
      extra={
        <Space>
          <Input
            onChange={(e) => handleChange(e)}
            className={`w-100 ${styles.searchbar}`}
            justify="start"
            align="start"
            placeholder="Search by ID"
            prefix={<SearchOutlined />}
          />
          <Dropdown overlay={menu} trigger={['click']}>
            <Input
              value={searchText}
              onChange={handleSearchChange}
              prefix={<FilterOutlined />}
              placeholder="Status"
            />
          </Dropdown>
          {/* <Input
            onChange={(e) => handleChange(e)}
            className={`w-100 ${styles.searchbar}`}
            justify="start"
            align="start"
            prefix={<FilterOutlined />}
            placeholder="Status"
          /> */}
          <RangePicker
            onChange={(e) => handleDateChange(e)}
            className={`w-100 ${styles.searchbar}`}
            // justify="start"
            // align="start"
            // placeholder="Search by material code"
          />
        </Space>
      }
    >
      <Tabs
        defaultActiveKey={category_id}
        type="card"
        onChange={callback}
        className={`${styles.customTab}`}
      >
        {data?.map((item) => {
          return (
            <Tabs.TabPane
              tab={item?.category}
              key={item?.id}
              // className={styles.customTab}
            >
              {/* <div className={`${styles.table_container}`}> */}
              <CustomTable
                locale={locale}
                loading={loading}
                columns={columns}
                dataSource={categorydata}
                pagination={{
                  size: 'small',
                  total: pagination?.total_records || 0,
                  showSizeChanger: false,
                  onChange: (page) => {
                    setPage(page);
                  }
                }}
                // className={styles.scrolling}
                // scroll={{ x: 'auto' }}
              />
              {/* </div> */}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </Card>
  );
};
export default TrackerListPage;
