import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { get_activities, create } from './api';

const initialState = {
  loading: false
};

const actions = {
  GET_ACTIVITIES: 'getActivities/GET_ACTIVITIES',
  GET_CATEGORIES_BY_ID: 'getCategoriesById/GET_CATEGORIES_BY_ID',
  CREATE_EQ_TRACKER: 'createEqTracker/CREATE_EQ_TRACKER',
  CREATE_CATEGORY: 'createCategory/CREATE_CATEGORY'
};

export const getActivities = createAsyncThunk(
  actions.GET_ACTIVITIES,
  async (payload) => {
    const response = await get_activities('get-category', payload);
    return response;
  }
);

export const getCategoriesById = createAsyncThunk(
  actions.GET_CATEGORIES_BY_ID,
  async (payload) => {
    const response = await get_activities('get', payload);
    return response;
  }
);

export const createEqTracker = createAsyncThunk(
  actions.CREATE_EQ_TRACKER,
  async (payload) => {
    const response = await create('create-eq-tracker', payload);
    return response;
  }
);

export const createCategory = createAsyncThunk(
  actions.CREATE_CATEGORY,
  async (payload) => {
    const response = await create('create', payload);
    return response;
  }
);

export const matricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActivities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActivities.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getActivities.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(getCategoriesById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCategoriesById.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(getCategoriesById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createEqTracker.pending, (state) => {
        state.loading = true;
      })
      .addCase(createEqTracker.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createEqTracker.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.loading = false;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(createCategory.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});
export default matricsSlice.reducer;
