import React, { useState, useEffect } from 'react';
import Layout from '@/common/Layout';
import {
  Card,
  Col,
  Row,
  Select,
  Form,
  DatePicker,
  Input,
  Space,
  Upload,
  Button,
  Tag
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllSuppliers,
  craeteNewInvoice,
  getAllInvoicesList,
  getCompletedRoles
} from '../../redux/slice';
import { getLoggedInUser } from 'utilities/helpers';
import moment from 'moment';
import { history } from '@/app/history';
import { useParams } from 'react-router-dom';
import { EditOutlined, PaperClipOutlined } from '@ant-design/icons';
import CustomModal from '@/common/CustomModal';
import { getAllDepartments } from '@/features/admin/redux/slice';
const CreatInvoice = () => {
  const { allDepartments } = useSelector((state) => state.admin);
  const { completedRoles } = useSelector((state) => state.invoiceTracker);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const params = useParams();

  const [search, setSearch] = useState('');
  const [suppliersdata, setSuppliersdata] = useState([]);
  const [type, setType] = useState('');
  const [fileList, setFileList] = useState([]);
  const [approved, setApproved] = useState('');
  const [invStatus, setInvStatus] = useState('');
  const [ismodel, setIsmodel] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [data, setData] = useState([]);
  const [assignedId, setAssignedId] = useState('');
  const [userID, setUserID] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const user = getLoggedInUser();

  useEffect(() => {
    handleGetAllSuppliers();
    handleGetAllDepartments();
    params.id && handleGetInvTrackers();
    params.id && handleGetCompletedProjects();
  }, [search]);

  const handleGetAllDepartments = () => {
    const payload = { search };
    dispatch(getAllDepartments(payload));
  };

  const handleGetCompletedProjects = () => {
    const payload = { id: params?.id };
    dispatch(getCompletedRoles(payload));
  };

  const handleGetAllSuppliers = () => {
    let payload = { search };
    dispatch(getAllSuppliers(payload)).then((res) => {
      if (res?.payload?.success) {
        setSuppliersdata(res?.payload?.data);
      }
    });
  };

  console.log(allDepartments, 'allDepartments');
  const handleGetInvTrackers = () => {
    const payload = { id: params.id };
    dispatch(getAllInvoicesList(payload)).then((res) => {
      if (res?.payload?.success) {
        setFileList(res?.payload?.data?.[0]?.inv_tracker_documents);
        setApproved(res?.payload?.data?.[0]?.done_by);
        setData(res?.payload?.data);
        setAssignedId(res?.payload?.data?.[0]?.current_stage);
        form.setFieldsValue({
          po_no: res.payload.data?.[0]?.po_no,
          po_date: res.payload.data?.[0]?.po_date
            ? moment(res.payload.data?.[0]?.po_date, 'YYYY-MM-DD')
            : '',
          supplier_id: res.payload.data?.[0]?.supplier_id,
          invoice_no: res.payload.data?.[0]?.invoice_no,
          invoice_date: res.payload.data?.[0]?.invoice_date
            ? moment(res.payload.data?.[0]?.invoice_date, 'YYYY-MM-DD')
            : '',
          invoice_recieved_date: res.payload.data?.[0]?.invoice_recieved_date
            ? moment(res.payload.data?.[0]?.invoice_recieved_date, 'YYYY-MM-DD')
            : '',
          gate_entryno: res.payload.data?.[0]?.gate_entryno,
          gate_entry_date: res.payload.data?.[0]?.gate_entry_date
            ? moment(res.payload.data?.[0]?.gate_entry_date, 'YYYY-MM-DD')
            : '',
          mrn_no: res.payload.data?.[0]?.mrn_no,
          mrn_date: res.payload.data?.[0]?.mrn_date
            ? moment(res.payload.data?.[0]?.mrn_date, 'YYYY-MM-DD')
            : '',
          assign_to: res.payload.data?.[0]?.department_id,
          mrn_recieved_date: res.payload.data?.[0]?.mrn_recieved_date
            ? moment(res.payload.data?.[0]?.mrn_recieved_date, 'YYYY-MM-DD')
            : '',
          cheque_no: res.payload.data?.[0]?.cheque_no,
          cheque_date: res.payload.data?.[0]?.cheque_date
            ? moment(res.payload.data?.[0]?.cheque_date, 'YYYY-MM-DD')
            : '',
          amount: res.payload.data?.[0]?.amount,
          audit_clearing_date: res.payload.data?.[0]?.audit_clearing_date
            ? moment(res.payload.data?.[0]?.audit_clearing_date, 'YYYY-MM-DD')
            : ''
        });
      }
    });
  };

  const onFinish = (values) => {
    console.log(values, type);
    let formData = new FormData();
    if (params.id && type !== '') {
      formData.append('id', params.id);
      formData.append(
        'type',
        data?.[0]?.status === 'Rejected' ? data?.[0]?.rejected_by : type
      );
      formData.append('status', invStatus);
      formData.append('remarks', remarks);
    }
    if (params.id && user?.user?.role === 'gate_entry') {
      formData.append('id', params.id);
      formData.append(
        'type',
        data?.[0]?.status === 'Rejected'
          ? data?.[0]?.rejected_by
          : 'physical_audit'
      );
      formData.append('status', invStatus);
    }
    formData.append('is_edit', isEdit);
    if (user?.user?.role === 'store_dept') {
      formData.append('mrn_date', values.mrn_date);
      formData.append('mrn_no', values.mrn_no);
      formData.append('assign_to', values.assign_to);
    }
    if (params.id && user?.user?.role !== 'gate_entry') {
      formData.append('mrn_recieved_date', values.mrn_recieved_date || '');
      formData.append(
        'is_user_approved',
        values.mrn_recieved_date ? true : false
      );

      formData.append('cheque_no', values.cheque_no || '');
      formData.append('cheque_date', values.cheque_date || '');
      formData.append('amount', values.amount || '');
      formData.append('audit_clearing_date', values.audit_clearing_date || '');
      formData.append(
        'cheque_clearing_date',
        values.cheque_clearing_date || ''
      );
      formData.append(
        'is_approved',
        values.cheque_clearing_date ? 'true' : 'false'
      );
      formData.append('return_to', userID || '');
    }
    if (user?.user?.role === 'gate_entry') {
      formData.append('supplier_id', values.supplier_id);
      formData.append('po_no', values.po_no);
      formData.append('po_date', values.po_date);
      formData.append('invoice_no', values.invoice_no);
      formData.append('invoice_date', values.invoice_date);
      formData.append('invoice_recieved_date', values.invoice_recieved_date);
      formData.append('gate_entryno', values.gate_entryno);
      formData.append('gate_entry_date', values.gate_entry_date);
      fileList?.map((file) => {
        formData.append('files', file.originFileObj);
      });
    }

    dispatch(craeteNewInvoice(formData)).then((res) => {
      if (res?.payload?.success) {
        history.push('/invoice-tracker');
      }
    });
  };

  const handleClose = () => {
    history.push('/invoice-tracker');
  };

  const handleCloseModel = () => {
    setIsmodel(false);
  };
  const onSearch = (value) => {
    setSearch(value);
  };

  const handleOnChangePdfUpload = (info) => {
    console.log(info);
    setFileList(info.fileList);
  };

  const handleUpdateStatus = (status, type) => {
    setInvStatus(status);
    setType(type);
    setIsmodel(true);
  };
  const handleDelete = (removedTag, id) => {
    // if (id !== '') {
    //   const payload = {
    //     id: id
    //   };
    //   dispatch(DeleteAttachment(payload)).then((res) => {
    //     if (res?.payload.status) {
    //       message.success('File deleted successfully');
    //       handleGetDocsbyId();
    //     }
    //   });
    // }
    console.log(id);
    const newTags = fileList.filter((tag) => tag.name !== removedTag);
    setFileList(newTags);
  };

  const handleRemarks = (e) => {
    setRemarks(e.target.value);
  };

  const handleOnchange = (e) => {
    const assigned_id = allDepartments.filter(
      (department) => department.id === e
    );
    setAssignedId(assigned_id?.[0]?.dept_name);
  };
  const handleModalSubmit = () => {
    // console.log('Modal');
    // onFinish();
    form.submit();
  };

  const onChangerole = (e) => {
    setUserID(e);
  };
  const nextType = [
    {
      current: 'physical_audit',
      next: 'store_dept'
    },
    {
      current: 'store_dept',
      next: data?.[0]?.is_user_approved ? 'audit_dept' : assignedId
    },
    {
      current: assignedId,
      next:
        assignedId === 'audit_dept'
          ? 'site_account_dept'
          : assignedId === 'site_account_dept'
          ? 'scm_dept'
          : assignedId === 'scm_dept'
          ? 'corporate_account_dept'
          : assignedId === 'corporate_account_dept'
          ? 'corporate_audit_dept'
          : assignedId === 'corporate_audit_dept'
          ? 'corporate_account_dept'
          : 'store_dept'
    }
  ];
  const nextItem = nextType.find((item) => item.current === user?.user?.role);
  console.log(nextItem);
  return (
    <Layout sider={false}>
      <Card
        title="Gate Entry"
        className={`${styles.cardStyles_approver}`}
        extra={
          (user?.user?.role === 'gate_entry' &&
            data?.[0]?.current_stage === 'physical_audit') ||
          (user?.user?.role === 'gate_entry' &&
            data?.[0]?.status === 'Rejected') ? (
            <EditOutlined onClick={() => setIsEdit(true)} />
          ) : (
            ''
          )
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={styles.panelborder}
        >
          <Row gutter={24}>
            <Col span={8}>
              <FormItem
                label="PO number"
                name="po_no"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter PO number"
                  disabled={params?.id && !isEdit}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="PO date"
                name="po_date"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={params?.id && !isEdit}
                />
              </FormItem>
            </Col>
            {/* </Row>
          <Row gutter={24}> */}
            <Col span={8}>
              <FormItem
                label="Vendor name"
                name="supplier_id"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  onSearch={onSearch}
                  placeholder="Select a vendor name"
                  optionFilterProp="children"
                  disabled={params?.id && !isEdit}
                  //   onChange={onChange}
                >
                  {suppliersdata?.map((supplier, index) => {
                    return (
                      <option value={supplier.id} key={index}>
                        {supplier.name}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Invoice number"
                name="invoice_no"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter invoice number"
                  disabled={params?.id && !isEdit}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Invoice date"
                name="invoice_date"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={params?.id && !isEdit}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Invoice rec date"
                name="invoice_recieved_date"
                rules={[{ required: false }]}
                className={styles.customLable}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={params?.id && !isEdit}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Gate entry number"
                name="gate_entryno"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <Input
                  placeholder="Enter gate entry number"
                  disabled={params?.id && !isEdit}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Gate entry date"
                name="gate_entry_date"
                rules={[{ required: true }]}
                className={styles.customLable}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  disabled={params?.id && !isEdit}
                />
              </FormItem>
            </Col>
            <Col xs={24} xl={8} span={8}>
              <FormItem
                label="Upload a File"
                // name="coa_coc_msda_available"
                rules={[
                  {
                    required: true,
                    message: 'Choose Yes/No'
                  }
                ]}
                className={styles.customLable}
              >
                {user?.user?.role === 'gate_entry' && (
                  <Upload
                    accept=".pdf"
                    fileList={fileList}
                    className={`avatar-uploader ${styles.upload}`}
                    onChange={handleOnChangePdfUpload}
                    customRequest={({ onSuccess }) => {
                      setTimeout(() => {
                        onSuccess('ok');
                      }, 0);
                    }}
                    multiple={true}
                    showUploadList={false}
                    disabled={params?.id && !isEdit}
                  >
                    <Button
                      icon={<PaperClipOutlined />}
                      className={styles.upload_button}
                      style={{ width: '100%', borderRadius: '12px' }}
                    >
                      Attach File
                    </Button>
                  </Upload>
                )}
                {/* <br />
                <br /> */}
                {fileList?.length > 0
                  ? fileList.map(({ doc_name, name, id, index }) => (
                      <Tag
                        closable
                        onClose={(e) => {
                          e.preventDefault();
                          handleDelete(doc_name || name, id);
                        }}
                        key={index}
                      >
                        {doc_name || name}
                      </Tag>
                    ))
                  : ''}
              </FormItem>
            </Col>

            {fileList?.length > 0 && (
              <>
                <br />
                <br />
              </>
            )}
            {user?.user?.role !== 'gate_entry' &&
            user?.user?.role !== 'physical_audit' ? (
              <>
                <Col span={8}>
                  <FormItem
                    label="MRN number"
                    name="mrn_no"
                    rules={[{ required: true }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter gate entry number"
                      disabled={params?.id && user?.user?.role !== 'store_dept'}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="MRN Date"
                    name="mrn_date"
                    rules={[{ required: true }]}
                    className={styles.customLable}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      disabled={params?.id && user?.user?.role !== 'store_dept'}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Assign to"
                    name="assign_to"
                    rules={[{ required: false }]}
                    className={styles.customLable}
                  >
                    <Select
                      showSearch
                      onSearch={onSearch}
                      onChange={handleOnchange}
                      placeholder="Select a department"
                      optionFilterProp="children"
                      disabled={params?.id && user?.user?.role !== 'store_dept'}
                    >
                      {allDepartments?.map((department, index) => {
                        return (
                          <option value={department.id} key={index}>
                            {department.department}
                          </option>
                        );
                      })}
                    </Select>
                  </FormItem>
                </Col>
                {user?.user?.role !== 'store_dept' && (
                  <Col span={8}>
                    <FormItem
                      label="MRN Received Date"
                      name="mrn_recieved_date"
                      rules={[{ required: true }]}
                      className={styles.customLable}
                    >
                      <DatePicker style={{ width: '100%' }} />
                    </FormItem>
                  </Col>
                )}
              </>
            ) : (
              ''
            )}
            {(user?.user?.role === 'corporate_account_dept' ||
              user?.user?.role === 'corporate_audit_dept') && (
              <>
                <Col span={8}>
                  <FormItem
                    label="Cheque number"
                    name="cheque_no"
                    rules={[{ required: true }]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter cheque number" />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Cheque Date"
                    name="cheque_date"
                    rules={[{ required: true }]}
                    className={styles.customLable}
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label="Amount"
                    name="amount"
                    rules={[{ required: true }]}
                    className={styles.customLable}
                  >
                    <Input placeholder="Enter amount" />
                  </FormItem>
                </Col>
                {data?.[0]?.audit_clearing_date &&
                  user?.user?.role === 'corporate_account_dept' && (
                    <Col span={8}>
                      <FormItem
                        label="Cheque Clearing Date"
                        name="cheque_clearing_date"
                        rules={[{ required: true }]}
                        className={styles.customLable}
                      >
                        <DatePicker style={{ width: '100%' }} />
                      </FormItem>
                    </Col>
                  )}
              </>
            )}
            {user?.user?.role === 'corporate_audit_dept' && (
              <Col span={8}>
                <FormItem
                  label="Audit Clearing Date"
                  name="audit_clearing_date"
                  rules={[{ required: true }]}
                  className={styles.customLable}
                >
                  <DatePicker style={{ width: '100%' }} />
                </FormItem>
              </Col>
            )}
          </Row>
          <Card className={`${styles.cardStyles_approver1111}`}>
            <Row gutter={24}>
              {user?.user?.role === 'gate_entry' ? (
                <Col span={8}>
                  <FormItem
                    label="Gate Entry (User ID, Date, Time)"
                    rules={[{ required: true }]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Approved By"
                      disabled
                      value={`${user?.user?.employee}(${moment().format(
                        'DD-MM-YYYY'
                      )})`}
                    />
                  </FormItem>
                </Col>
              ) : (
                <>
                  <Col span={8}>
                    <FormItem
                      label="Gate Entry (User ID, Date, Time)"
                      rules={[{ required: true }]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Approved By"
                        disabled
                        value={approved}
                      />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem
                      label="Physical Audit (User ID, Date, Time)"
                      rules={[{ required: true }]}
                      className={styles.customLable}
                    >
                      <Input
                        placeholder="Enter Approved By"
                        disabled
                        value={`${user?.user?.employee}(${moment().format(
                          'DD-MM-YYYY'
                        )})`}
                      />
                    </FormItem>
                  </Col>
                </>
              )}
            </Row>
          </Card>
          <br />
          <FormItem className="text-center">
            <Space>
              <CustomButton
                className={styles.inwardButton}
                onClick={handleClose}
                type="primary"
                // size="small"
                ghost
              >
                Close
              </CustomButton>
              {user?.user?.role === data?.[0]?.current_stage ? (
                <Space>
                  <CustomButton
                    className={styles.inwardButton_reject}
                    // htmlType="submit"
                    // type="primary"
                    // onClick={() => setType('physical_audit')}
                    onClick={() =>
                      handleUpdateStatus('Rejected', nextItem?.next)
                    }
                    // size="small"
                  >
                    Reject
                  </CustomButton>
                  <CustomButton
                    className={styles.inwardButton_approve}
                    // htmlType="submit"
                    // type="primary"
                    onClick={() =>
                      handleUpdateStatus('Approved', nextItem?.next)
                    }
                    // size="small"
                  >
                    Approve
                  </CustomButton>
                </Space>
              ) : !params?.id && !isEdit ? (
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                >
                  Approve
                </CustomButton>
              ) : (
                isEdit && (
                  <CustomButton
                    className={styles.inwardButton}
                    htmlType="submit"
                    type="primary"
                  >
                    Update
                  </CustomButton>
                )
              )}
            </Space>
          </FormItem>
          {ismodel ? (
            <CustomModal
              title={`Are you sure you want to ${
                invStatus == 'Approved' ? 'Approve' : 'Reject'
              } this Invoice`}
              visible={ismodel}
              width="50%"
              footer={null}
              className={styles.customModal}
              closable={false}
            >
              {/* <Form form={form} layout="vertical"> */}

              <Row gutter={24}>
                {invStatus === 'Rejected' && (
                  <Col span={24}>
                    <FormItem
                      label="Return to"
                      // name="remarks"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Remarks'
                        }
                      ]}
                      className={styles.customLable}
                    >
                      <Select
                        showSearch
                        onSearch={onSearch}
                        placeholder="Select a department"
                        optionFilterProp="children"
                        // disabled={params?.id && !isEdit}
                        onChange={(e) => onChangerole(e)}
                      >
                        {completedRoles?.map((supplier, index) => {
                          return (
                            <option value={supplier.status} key={index}>
                              {supplier.status}
                            </option>
                          );
                        })}
                      </Select>
                    </FormItem>
                  </Col>
                )}
                <Col span={24}>
                  <FormItem
                    label="Remarks"
                    // name="remarks"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Remarks'
                      }
                    ]}
                    className={styles.customLable}
                  >
                    <Input
                      placeholder="Enter Remarks"
                      onChange={(e) => handleRemarks(e)}
                    />
                  </FormItem>
                </Col>
              </Row>
              <FormItem className="text-center">
                <Space>
                  <CustomButton
                    className={styles.inwardButton}
                    onClick={handleCloseModel}
                    type="primary"
                    size="small"
                    ghost
                  >
                    No
                  </CustomButton>
                  <CustomButton
                    className={styles.inwardButton}
                    // htmlType="submit"
                    type="primary"
                    size="small"
                    onClick={handleModalSubmit}
                  >
                    Yes
                  </CustomButton>
                </Space>
              </FormItem>
              {/* </Form> */}
            </CustomModal>
          ) : (
            ''
          )}
        </Form>
      </Card>
    </Layout>
  );
};

export default CreatInvoice;
