import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Card, Form, Tag, Space } from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import Layout from '@/common/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
import { getAllBinPositions } from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import { getLoggedInUser } from 'utilities/helpers';
import { getProducts } from '@/features/productionLabelGen/redux/slice';

const TrackBinPage = () => {
  const [form] = Form.useForm();
  const { allMaterials } = useSelector((state) => state.stocks);

  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [code, setCode] = useState('');
  const [batches, setBatches] = useState([]);
  const [containers, setContainers] = useState([]);
  const [data, setData] = useState([]);
  console.log('state.staocks ', allMaterials);

  const { user } = getLoggedInUser();

  useEffect(() => {
    handleGetAllMaterialsList();
  }, [search, code]);

  const handleGetAllMaterialsList = () => {
    let payload = { search };
    user?.role === 'prod_user'
      ? dispatch(getProducts(payload)).then(function (response) {
          if (response?.payload?.success) {
            console.log(response?.payload?.data);
            setData(response?.payload?.data);
          }
        })
      : dispatch(getAllMaterialslist(payload));
  };

  // const handleGetAllMaterialsList = () => {
  //   let payload = { search };
  //   dispatch(getAllMaterialslist(payload));
  // };

  const onChange = (value, type) => {
    form.resetFields(['in_house_batch_number']);
    const material = allMaterials.filter(
      (MaterialsList) => MaterialsList.id === value
    );

    let payload = { search: material[0].code };
    dispatch(getAllMaterialslist(payload)).then((response) => {
      if (response?.payload?.success) {
        // resetField('in_house_batch_number');

        setBatches(response?.payload?.data[0]?.batch_details);
        dispatch(getAllMaterialslist());
      }
    });
    form.setFieldsValue({ supplier_id: null });
    type == 'id'
      ? form.setFieldsValue({ material_name: value })
      : form.setFieldsValue({ material_id: value });
    setCode(material[0]);
  };

  const onFinish = (values) => {
    console.log(values);
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const onChangeBatch = (value) => {
    // const batches_details =
    //   value == 'all'
    //     ? allMaterials[0]?.batch_details
    //     : batches.filter((batches) => batches.id === value);
    // setBatches(batches_details);
    const payload = { batch_details_id: value };
    dispatch(getAllBinPositions(payload)).then((response) => {
      if (response?.payload?.success) {
        // resetField('in_house_batch_number');
        setContainers(response?.payload?.data);
        // setBatches(response?.payload?.data[0]?.batch_details);
        // dispatch(getAllMaterialslist());
      }
    });
  };
  const onSearchBatch = (value) => {
    const batches_details = batches.inhouse_batch_number
      .toLowerCase()
      .includes(value.toLowerCase());
    setBatches(batches_details);
  };

  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card title="MATERIAL BIN LOCATION" className={styles.CustomPanel}>
          {user?.role !== 'prod_user' ? (
            <Row gutter={24}>
              <Col xs={24} xl={8} span={12} md={24} sm={24}>
                <FormItem
                  label="Material Code"
                  name="material_id"
                  rules={[
                    {
                      required: true,
                      message: 'Select Material Code'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    showSearch
                    placeholder="Select a material code"
                    optionFilterProp="children"
                    onChange={(e) => onChange(e, 'id')}
                    onSearch={onSearch}
                  >
                    {allMaterials?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.code}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={12} md={24} sm={24}>
                <FormItem
                  label="Material Name"
                  name="material_name"
                  rules={[
                    {
                      required: false,
                      message: 'Select Material Name'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    showSearch
                    placeholder="Select a material name"
                    optionFilterProp="children"
                    onChange={(e) => onChange(e, 'name')}
                    onSearch={onSearch}
                  >
                    {allMaterials?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Select>
                  {/* <Input value={`${code?.name || 'NA'}`} disabled /> */}
                </FormItem>
              </Col>
              <Col xs={24} xl={8} span={12} md={24} sm={24}>
                <FormItem
                  label="In House Batch Number"
                  name="in_house_batch_number"
                  rules={[
                    {
                      required: true,
                      message: 'Select In House Batch Number'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    showSearch
                    placeholder="Select In House Batch Number"
                    optionFilterProp="children"
                    onChange={onChangeBatch}
                    onSearch={onSearchBatch}
                  >
                    <option value="all">All</option>
                    {batches?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.inhouse_batch_number}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
            </Row>
          ) : (
            <Row gutter={24}>
              <Col xs={24} xl={8} span={12} md={24} sm={24}>
                <FormItem
                  label="Product Batch Number"
                  name="in_house_batch_number"
                  rules={[
                    {
                      required: true,
                      message: 'Select In House Batch Number'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Select
                    showSearch
                    placeholder="Select In Product Batch Number"
                    optionFilterProp="children"
                    onChange={onChangeBatch}
                    onSearch={onSearch}
                  >
                    <option value="all">All</option>
                    {data?.map((item, index) => {
                      return (
                        <option value={item.id} key={index}>
                          {item.batch_no}
                        </option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
            </Row>
          )}
        </Card>
        <br />
        <Card title="BIN LOCATIONS" className={styles.CustomPanel}>
          <Row>
            {containers?.map((item) => {
              return item?.bin?.bin_number ? (
                <Tag color="default" style={{ fontSize: '14px' }}>
                  {item?.bin?.bin_number}
                </Tag>
              ) : (
                ''
              );
            })}
          </Row>
        </Card>
        <br />
        <FormItem className="text-center">
          <Space>
            <CustomButton
              className={styles.inwardButton}
              type="primary"
              ghost
              onClick={() => history.back()}
            >
              Back
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </Layout>
  );
};
export default TrackBinPage;
