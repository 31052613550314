import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Tabs, Space, Select, Form } from 'antd';
import styles from './index.module.less';
import Layout from '@/common/Layout';
import { useDispatch } from 'react-redux';
import FormItem from '@/common/FormItem';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
// import moment from 'moment';
// import { Activity } from 'lucide-react';
import { getMetrics, getAllBatches } from './redux/slice';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { format } from 'date-fns';

// const { RangePicker } = DatePicker;

const blenderDashboard = () => {
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);
  const [form] = Form.useForm();
  // const [fileList, setFileList] = useState();
  // const [allBatches, setAllBatches] = useState([]);
  // const [docType, setDocType] = useState('');
  // const [printOptions, setPrintOptions] = useState('');
  // const dispatch = useDispatch();
  const [metrics, setMetrics] = useState([]);
  const [batchSizeData, setBatchSizeData] = useState([]);
  const [search, setSearch] = useState('');
  const [allBatches, setAllBatches] = useState([]);
  const [batchNum, setBatchNum] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    handleGetAllMetrics();
    handleGetBatches();
  }, [batchNum, search]);

  const handleGetBatches = () => {
    const payload = { search, asset_id: searchParams.get('id') };
    dispatch(getAllBatches(payload)).then((response) => {
      console.log(response);
      setAllBatches(response?.payload?.data);
      form.setFieldsValue({
        batch_number: batchNum
          ? batchNum
          : response?.payload?.data?.[0]?.BatchNo
      });
    });
  };

  console.log(batchNum);
  const handleGetAllMetrics = () => {
    const payload = {
      asset_id: searchParams.get('id'),
      batch_number: batchNum
    };
    dispatch(getMetrics(payload)).then((response) => {
      console.log(response);
      setMetrics(response?.payload?.data);
      setBatchSizeData(response?.payload?.data?.productStats);
    });
  };

  const onSearch = (e) => {
    setSearch(e);
  };

  const handleonChange = (e) => {
    console.log(e);
    setBatchNum(e);
  };

  const handleSeldctVendor = (value) => {
    setBatchNum(value);
  };

  const rpmData = [
    { time: '05:00', RPM: 3 },
    { time: '06:00', RPM: 4 },
    { time: '07:00', RPM: 6 },
    { time: '08:00', RPM: 9 },
    { time: '09:00', RPM: 9.5 },
    { time: '10:00', RPM: 3 },
    { time: '11:00', RPM: 5.6 },
    { time: '12:200', RPM: 4 }
  ];

  return (
    <Layout sider={false}>
      <Card
        className={`${styles.cardStyles_approver}`}
        title={`${metrics?.[0]?.equipment_category} Performance Dashboard`}
        extra={`${metrics?.[0]?.equipment_name}`}
      >
        {/* {metrics?.totalBatches ? ( */}
        <Form
          form={form}
          layout="vertical"
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
        >
          <Row gutter={24} style={{ marginBottom: '0px !important' }}>
            <Col span={6}>
              <FormItem
                label="Batch Number"
                name="batch_number"
                rules={[
                  {
                    required: false,
                    message: 'Enter Batch number'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Select batch number"
                  showSearch
                  onSearch={onSearch}
                  style={{ width: '100%' }}
                  onChange={(e) => handleonChange(e)}
                  onSelect={handleSeldctVendor}
                >
                  {allBatches?.map((batchNumber, index) => {
                    return (
                      <option value={batchNumber?.BatchNo} key={index}>
                        {batchNumber?.BatchNo}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
          </Row>
        </Form>
        <Row gutter={24}>
          {metrics?.map((item, index) => {
            return (
              <>
                <Col span={6} key={index} style={{ marginTop: '25px' }}>
                  <Card className={styles.BGimage}>
                    <Space
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      <span className="mb-2 text-gray-600">{item.name}</span>
                      {item.time && (
                        <span style={{ fontSize: '13px', marginLeft: 'auto' }}>
                          {`${moment(item.time).format('hh:mm A')}`}
                        </span>
                      )}
                    </Space>
                    <span style={{ fontSize: '40px' }}>{item?.last_value}</span>
                    {/* </CardContent> */}
                  </Card>
                </Col>
              </>
            );
          })}
        </Row>
        <br />

        <Tabs defaultActiveKey="1" type="card">
          {metrics?.map((item, index) => {
            const maxAverage =
              item?.average_data && Array.isArray(item?.average_data)
                ? Math.max(...item.average_data.map((d) => d.Average))
                : 0;
            return (
              <Tabs.TabPane tab={item?.name} key={index}>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={item?.average_data}
                      width={500}
                      height={300}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="time"
                        tickFormatter={(tick) =>
                          format(new Date(tick), 'HH:mm')
                        }
                      />
                      <YAxis domain={[0, parseInt(maxAverage) + 10]} />
                      <Tooltip />
                      {/* <Legend /> */}
                      <Line
                        type="monotone"
                        dataKey="Average"
                        stroke="#e80e94"
                        fill="#e80e94"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </Tabs.TabPane>
            );
          })}
        </Tabs>

        {/* </Card> */}
        {/* ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Select a date range above to view insights"
          />
        )} */}
      </Card>

      {/* Charts */}

      {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-4"> */}
      <br />
      {/* <Row gutter={24}> */}
      {/* <Space direction="vertical" size="12"> */}
      {metrics?.totalBatches > 100 ? (
        <>
          <Col span={24}>
            <Card
              className={`${styles.cardStyles_approver}`}
              title="Batch Sizes by Product"
            >
              <div className="h-64">
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={batchSizeData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="productName" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="AverageBatchSize" fill="#ea649c" />
                    <text
                      x="50%"
                      y="100%"
                      dy={20}
                      textAnchor="middle"
                      fontSize={18}
                      fontWeight="bold"
                      fill="#333"
                    >
                      Custom Title Below Bar Chart
                    </text>

                    {/* <Line type="monotone" dataKey="pv" stroke="#8884d8" />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>
          <br />
          {/* <br /> */}
          <Col span={24}>
            <Card
              title="RPM Trends"
              className={`${styles.cardStyles_approver}`}
            >
              <div className="h-64">
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={rpmData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="RPM" stroke="#c91761" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </Card>
          </Col>
        </>
      ) : (
        ''
      )}
      {/* </Space> */}
      {/* </Row> */}
    </Layout>
  );
};
export default blenderDashboard;
