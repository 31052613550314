import React, { useEffect, useState } from 'react';
import Layout from '@/common/Layout';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getCategoriesById, createEqTracker } from '../redux/slice';
import { Card, Col, DatePicker, Radio, Form, Row, Input, Space } from 'antd';
import styles from '@/common/Layout/index.module.less';
import FormItem from '@/common/FormItem';
import { getLoggedInUser } from 'utilities/helpers';
import moment from 'moment';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
const viewTracker = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const [categorydata, setCategorydata] = useState([]);
  const [activity, setActivity] = useState('');

  const user = getLoggedInUser();
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetCategoriesById();
  }, []);

  const handleGetCategoriesById = () => {
    const payload = {
      id: params.id
    };
    dispatch(getCategoriesById(payload)).then((res) => {
      if (res.payload.success) {
        setCategorydata(res.payload.data);
      } else {
        console.log('Error fetching categories');
      }
    });
  };

  const onFinish = (values) => {
    console.log('Received values of form:', values);
    const date = values['next_maint_date']
      ? moment(values['next_maint_date']._d)
      : '';
    date !== '' ? (values['next_maint_date'] = date) : '';
    values['eq_maintainence_id'] = params.id;
    dispatch(createEqTracker(values)).then((res) => {
      if (res.payload.success) {
        history.push('/activity-tracker-list');
      } else {
        console.log('Error fetching categories');
      }
    });
  };

  const onChange = (e) => {
    setActivity(e.target.value);
  };
  return (
    <Layout sider={false}>
      <Card
        title={`ID: ${categorydata?.[0]?.equipment_id}`}
        extra={`Name: ${categorydata?.[0]?.equipment_name}`}
        className={`${styles.cardStyles_approver}`}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={12}>
              <FormItem
                label="Is the maintenance activity performed?"
                name="maintainence_performed"
                rules={[
                  {
                    required: true,
                    message: 'Select maintainence performed'
                  }
                ]}
                className={styles.customLable}
              >
                <Radio.Group onChange={onChange}>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            <Col span={12}>
              {activity === 'yes' ? (
                <FormItem
                  label="Next planned maintenance date"
                  name="next_maint_date"
                  rules={[
                    {
                      required: true,
                      message: 'Select maintainence performed'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <DatePicker
                    placeholder="Select Date"
                    style={{ width: '100%' }}
                  />
                </FormItem>
              ) : activity === 'no' ? (
                <FormItem
                  label="Reason for non-maintanance"
                  name="non_maintainence_reason"
                  rules={[
                    {
                      required: true,
                      message: 'Select maintainence performed'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Radio.Group>
                    <Radio value="Equipment Breakdown">
                      Equipment Breakdown
                    </Radio>
                    <Radio value="Equipment Not in Use">
                      Equipment Not in Use
                    </Radio>
                  </Radio.Group>
                </FormItem>
              ) : (
                ''
              )}
            </Col>
            <Col span={12}>
              <FormItem
                label="Performed By(User ID & Date)"
                // name="user_id"
                rules={[
                  {
                    required: true,
                    message: 'Select maintainence performed'
                  }
                ]}
                className={styles.customLable}
              >
                <Input
                  placeholder=""
                  disabled
                  value={`${user?.user?.employee} ( ${moment().format(
                    'YYYY-MM-DD'
                  )})`}
                />
              </FormItem>
            </Col>
          </Row>
          <FormItem className="text-center">
            <Space>
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Submit
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </Card>
    </Layout>
  );
};
export default viewTracker;
