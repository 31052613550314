import apiClient from 'utilities/apiClient';

export const get_products = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.productDetails}/${url}`,
    payload,
    true
  );
};

export const createProduct = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.productDetails}/${url}`,
    payload,
    true
  );
};

export const deleteProductId = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.productDetails}/${url}`,
    payload,
    true
  );
};
