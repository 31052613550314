import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getAllInvoices, createTracker, getCompletedInv } from './api';

const initialState = {
  loading: false,
  allInvoices: [],
  allSuppliers: [],
  completedRoles: [],
  pagination: {},
  getAllMaterilLoading: false
};

const actions = {
  GET_ALL_INVOICES: 'getAllInvoicesList/GET_ALL_INVOICES',
  GET_ALL_SUPPLIERS: 'getAllSuppliers/GET_ALL_SUPPLIERS',
  CREATE_NEW_INVOICE: 'craeteNewInvoice/CREATE_NEW_INVOICE',
  GET_COMPLETED_PROJECTS: 'getCompletedRoles/GET_COMPLETED_PROJECTS'
};

export const getAllInvoicesList = createAsyncThunk(
  actions.GET_ALL_INVOICES,
  async (payload) => {
    const response = await getAllInvoices('get_inv_tracker', payload);
    return response;
  }
);

export const getAllSuppliers = createAsyncThunk(
  actions.GET_ALL_SUPPLIERS,
  async (payload) => {
    const response = await getAllInvoices('get_search_suppliers', payload);
    return response;
  }
);

export const craeteNewInvoice = createAsyncThunk(
  actions.CREATE_NEW_INVOICE,
  async (payload) => {
    const response = await createTracker('create', payload);
    return response;
  }
);

export const getCompletedRoles = createAsyncThunk(
  actions.GET_COMPLETED_PROJECTS,
  async (payload) => {
    const response = await getCompletedInv('get_completed_roles', payload);
    return response;
  }
);

export const invoiceTrackerSlice = createSlice({
  name: 'invoiceTracker',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllInvoicesList.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllInvoicesList.fulfilled, (state, action) => {
        const { success, data, pagination, message: msg } = action.payload;
        if (success) {
          state.allInvoices = data || [];
          state.pagination = pagination || null;
          state.getAllMaterilLoading = false;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAllInvoicesList.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getAllMaterilLoading = false;
        message.error(msg);
      });

    builder
      .addCase(getAllSuppliers.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getAllSuppliers.fulfilled, (state, action) => {
        const { success, data, pagination, message: msg } = action.payload;
        if (success) {
          state.allSuppliers = data || [];
          state.pagination = pagination || null;
          state.getAllMaterilLoading = false;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAllSuppliers.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getAllMaterilLoading = false;
        message.error(msg);
      });

    builder
      .addCase(craeteNewInvoice.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(craeteNewInvoice.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        if (!success) {
          message.error(msg);
        }
      })
      .addCase(craeteNewInvoice.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getAllMaterilLoading = false;
        message.error(msg);
      });

    builder
      .addCase(getCompletedRoles.pending, (state) => {
        state.getAllMaterilLoading = true;
      })
      .addCase(getCompletedRoles.fulfilled, (state, action) => {
        const { success, data, pagination, message: msg } = action.payload;
        if (success) {
          state.completedRoles = data || [];
          state.pagination = pagination || null;
          state.getAllMaterilLoading = false;
        } else {
          message.error(msg);
        }
      })
      .addCase(getCompletedRoles.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getAllMaterilLoading = false;
        message.error(msg);
      });
  }
});
export default invoiceTrackerSlice.reducer;
