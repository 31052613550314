import React, { useEffect, useState, useCallback } from 'react';
import { Card, Empty, Row, Col, Input, Space, Popconfirm } from 'antd';
import styles from '@/common/Layout/index.module.less';
import { useDispatch } from 'react-redux';
import { getProducts, deleteProduct } from '../redux/slice';
import CustomTable from 'common/Content/CustomTable';
import {
  CloseOutlined,
  EyeOutlined,
  // FormOutlined,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons';
import CustomButton from '@/common/CustomButton';
import moment from 'moment';

const ProductionListPage = ({ loading }) => {
  const dispatch = useDispatch();
  const [productsData, setProductsData] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState();
  const [search, setSearch] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);

  useEffect(() => {
    handleGetProductLabels();
  }, [page, search]);

  const handleGetProductLabels = () => {
    const payload = { page, search };
    dispatch(getProducts(payload)).then((res) => {
      if (res.payload.success) {
        setProductsData(res.payload.data);
        setPagination(res.payload.pagination);
      }
    });
  };
  const handleChange = (e) => {
    console.log(e);
    setSearch(e.target.value);
  };

  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);

  const handleDeleteUser = (record) => {
    console.log(record);
    const payload = { id: record.id };
    dispatch(deleteProduct(payload)).then(() => {
      handleGetProductLabels();
      setShowDisablePopup(false);
    });
  };

  const columns = [
    {
      title: 'Product Batch #',
      dataIndex: 'batch_no',
      key: 'batch_no',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Product Name',
      dataIndex: 'equipment_name',
      key: 'equipment_name',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        return record?.product_detail?.name;
      }
    },
    {
      title: 'Process',
      dataIndex: 'maintainence_frequency',
      key: 'maintainence_frequency',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        return record?.bom_process_master?.process_name;
      }
    },
    {
      title: 'Process Group',
      dataIndex: 'date_planned',
      key: 'date_planned',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        return record?.bom_process_group_master?.process_group_name;
      }
    },
    {
      title: 'No.of Containers',
      dataIndex: 'no_of_containers',
      key: 'no_of_containers',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md']
    },
    {
      title: 'Generated date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (text) => {
        return moment(text).format('YYYY-MM-DD');
      }
    },
    {
      title: 'Action',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      responsive: ['xl', 'xs', 'sm', 'xxl', 'md'],
      render: (_, record) => {
        {
          return (
            <Space>
              <a href={`/createLabel?id=${record.id}`}>
                <EyeOutlined />
              </a>
              {/* <a href={`/createLabel?id=${record.id}`}>
                <FormOutlined />
              </a> */}
              <Popconfirm
                title={`Are you sure do you want to Inactive this Product batch`}
                visible={visibility(record)}
                key={record.id}
                onConfirm={(e) => {
                  e.stopPropagation();
                  handleDeleteUser({
                    id: record.id
                  });
                }}
                onCancel={(e) => {
                  e.stopPropagation();
                  setShowDisablePopup({ [record.id]: false });
                }}
                okText={record.is_active ? 'Deactivate' : 'Activate'}
                cancelText="No"
                okButtonProps={{
                  type: record.is_active ? 'danger' : 'primary'
                }}
              >
                <CloseOutlined />
              </Popconfirm>
            </Space>
          );
        }
      }
    }
  ];
  let locale = {
    emptyText: (
      <span>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        <div className={styles.empty_state_text}>
          There are no material requests to display
        </div>
      </span>
    )
  };

  return (
    <>
      <Row gutter={24}>
        <Col span="20"></Col>
        <Col span="4">
          <Input
            onChange={(e) => handleChange(e)}
            className={`w-100 ${styles.searchbar}`}
            justify="start"
            align="start"
            placeholder="Search by Batch #"
            prefix={<SearchOutlined />}
          />
        </Col>
      </Row>

      <Card
        title="Production Label Generation"
        className={`${styles.cardStyles_approver}`}
        extra={
          <CustomButton
            type="primary"
            onClick={() => window.location.replace('/createLabel')}
          >
            <PlusOutlined /> Generate new labels
          </CustomButton>
        }
      >
        <CustomTable
          locale={locale}
          loading={loading}
          columns={columns}
          dataSource={productsData}
          pagination={{
            size: 'small',
            total: pagination?.total_records || 0,
            showSizeChanger: false,
            onChange: (page) => {
              setPage(page);
            }
          }}
          className={styles.scrolling}
          // scroll={{ x: 'auto' }}
        />
        {/* </div> */}
      </Card>
    </>
  );
};
export default ProductionListPage;
