import React, { useEffect, useState } from 'react';
import { Card, Image, Space, Row, Col, Form } from 'antd';
// import ApprovalMissingDoc from '../../images/approval-for-missing-documents.svg';
// import ApprovalStorageFailure from '../../images/approval-for-storage-inspection-failure.svg';
// import ApprovalDamagedReport from '../../images/approval-for-damages-reported.svg';
// import ApprovalDiscrepency from '../../images/approval-for-discrepancy-raised.svg';
// import ApprovalLCDispense from '../../images/approval-for-LC-to-dispense-material.svg';
import truck from '@/images/ReceiveMaterial.png';
import Gatepasstruck from '@/images/MoveMaterial.png';
import QCtruck from '@/images/Stock-Reconciliation.png';
import Dispense from '@/images/DispenseMaterial.png';
import WastageIcon from '@/images/WastageMaterial.png';
import MaterialReturnIcon from '@/images/MaterialReturnRequest.png';
import LogBooksIcon from '@/images/LogBooks.png';
import CodetocodeIcon from '@/images/Codetocode.png';
import AddNewProduct from '@/images/Add-New-Product.png';
import AddNewVendorsMaterials from '@/images/Add-New-Vendors-Materials.png';
import approvalfordamagesreported from '@/images/approval-for-damages-reported.png';
import approvalfordiscrepancyraised from '@/images/approval-for-discrepancy-raised.png';
import ApprovalforEquipmentLCeBMR from '@/images/Approval-for-Equipment-LCeBMR.png';
import approvalforLCtodispensematerial from '@/images/approval-for-LC-to-dispense-material.png';
import Approvalformaterialbaseddispensing from '@/images/Approval-for-material-based-dispensing.png';
import approvalformissingdocuments from '@/images/approval-for-missing-documents.png';
import approvalforstockreconciliation from '@/images/approval-for-stock-reconciliation.png';
import approvalforstorageinspectionfailure from '@/images/approval-for-storage-inspection-failure.png';
import ApprovalforWastageMaterial from '@/images/Approval-for-Wastage-Material.png';
import BatchApproval from '@/images/Batch-Approval.png';
import BatchnumberingLogbook from '@/images/Batch-numbering-Log-book.png';
import CreateDispenserequest from '@/images/Create-Dispense-request.png';
import Departments from '@/images/Departments.png';
import Designations from '@/images/Designations.png';
import InvoiceDetails from '@/images/Invoice-details.png';
// import DispenseMaterial from '@/images/DispenseMaterial.png';
import Listofdispensingmaterials from '@/images/List-of-dispensing-materials.png';
import ExecuteBMR from '@/images/Execute-BMR.png';
import ProductMaster from '@/images/Product-Master.png';
import EBMRMaster from '@/images/EBMR-Master.png';
import SampleMaterial from '@/images/Sample-Material.png';
import TestRequestForms from '@/images/TestRequestForms.png';
import materialdetails from '@/images/material-details.png';
import UserManagement from '@/images/User-Management.png';
import MetricCard from '@/images/Equipment-Intelligence-Centre.png';
import ActivtyTracker from '@/images/Activity-Tracker.png';
import Masters from '@/images/Master.png';
import ProductionLabelGeneration from '@/images/Production-Label-Generation.png';
import DMS from '@/images/DMS.png';
import BMR from '@/images/BMR.png';
import InvoiceTracker from '@/images/Invoice-tracker.png';
// import documentverification from '@/images/document-verification.png';
import FileImage from '../../images/file-icon.svg';
// import temp from '../../images/Untitled.png';
// import CustomButton  from '@/common/CustomButton';
import styles from './index.module.less';
// import { ArrowRightOutlined } from '@ant-design/icons';
import { getLoggedInUser } from 'utilities/helpers';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getMetricsMasters } from '@/features/blenderDashboard/redux/slice';

const DashBoardPage = () => {
  const [form] = Form.useForm();
  const { user } = getLoggedInUser();
  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);

  const [equipments, setEquipments] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    handleGetEquiplents();
  }, []);

  const handleGetEquiplents = () => {
    dispatch(getMetricsMasters()).then((response) => {
      setEquipments(response?.payload?.data);
    });
  };

  console.log(equipments);
  const cards = [
    {
      ////title: 'Receive Material',
      icon: truck,
      url: '/pending-receipts'
    },
    {
      //title: 'Move Material',
      icon: Gatepasstruck,
      url: '/landingpage'
    },
    {
      //title: 'Stock Reconciliation',
      icon: QCtruck,
      url: '/stock-reconsilation'
    },
    {
      //title: `Dispense Material`,
      icon: Dispense,
      url: '/dispense-material-list'
    },
    // {
    //   //title: 'Sampling Material',
    //   icon: truck,
    //   url: user?.lead === true ? '/samples-list' : '/sampling'
    // },
    {
      //title: 'Wastage Material',
      icon: WastageIcon,
      url: '/wastage-reconsilation'
    },
    {
      //title: 'Material Return Request',
      icon: MaterialReturnIcon,
      url: 'material-return-request'
    },
    {
      //title: 'Log Books',
      icon: LogBooksIcon,
      url: 'log-books'
    },
    {
      //title: 'Code to Code Transfer',
      icon: CodetocodeIcon,
      url: 'code-to-code-list'
    },
    {
      //title: 'Code to Code Transfer',
      icon: InvoiceTracker,
      url: 'invoice-tracker'
    }
  ];

  const invoiceTrackCard = [
    {
      icon: InvoiceTracker,
      url: 'invoice-tracker'
    }
  ];

  const dmsCard = [
    {
      //title: 'DMS',
      icon: DMS,
      url: '/requisition-slip'
      // url: '/document-dms'
    }
  ];

  const metricsCard = [
    {
      // title: item.eqp_name,
      icon: MetricCard,
      url: `metrics`
    }
  ];

  const ED = [
    {
      icon: ActivtyTracker,
      url: `activity-tracker-list`
    },
    {
      icon: Masters,
      url: `create-master`
    }
  ];
  // const metricsCard = equipments?.map((item) => {
  //   return {
  //     // title: item.eqp_name,
  //     icon: MetricCard,
  //     url: `blender-dashboard?id=${item.asset_id}`
  //   };
  // });
  // [
  //   {
  //     title: 'Equipment Intelligence Center',
  //     icon: truck,
  //     url: 'blender-dashboard'
  //   }
  // ];
  // const Documentcards = [
  //   {
  //     //title: 'BMR',
  //     icon: truck,
  //     url: '/document-bmrs'
  //   },
  // ];

  const QAcards = [
    {
      //title: 'Create Dispense Request',
      icon: CreateDispenserequest,
      url: 'dispensing'
    },
    // {
    //   //title: 'Approvals',
    //   icon: Approvalformaterialbaseddispensing,
    //   url: 'dashboard?type="approvals"'
    // },
    {
      //title: 'Missing Document',
      icon: approvalformissingdocuments,
      url: 'qc-list?qc=doc_ver_pending'
    },
    {
      //title: 'Storage Inspection Failure',
      icon: approvalforstorageinspectionfailure,
      url: 'qc-list?qc=veh_ver_pending'
    },
    {
      //title: 'Damages Reported',
      icon: approvalfordamagesreported,
      url: 'qc-list?qc=dmg_ver_pending'
    },
    {
      //title: 'Discrepency Raised',
      icon: approvalfordiscrepancyraised,
      url: 'qc-list?qc=wh_ver_pending'
    },
    {
      //title: 'LC to Dispense Material',
      icon: approvalforLCtodispensematerial,
      url: 'dispense-material-list'
    },
    {
      //title: 'Stock Reconciliation',
      icon: approvalforstockreconciliation,
      url: 'stock-reconsilation'
    },
    {
      //title: 'Wastage Material',
      icon: ApprovalforWastageMaterial,
      url: 'wastage-reconsilation'
    },
    {
      //title:
      // user?.level == 3 ? 'New Vendor/Material' : 'Add New Vendor/Material',
      icon: AddNewVendorsMaterials,
      url: 'vendor-material'
    },
    {
      // //title: 'Material based Dispensing Request',
      icon: Approvalformaterialbaseddispensing,
      url: 'dispense-qa-materials-list'
    },
    {
      //title: 'Material Return Request',
      icon: MaterialReturnIcon,
      url: 'material-return-request'
    },
    {
      //title: 'Batch Numbering Log Book',
      icon: BatchnumberingLogbook,
      url: 'log-book-list'
    },
    {
      //title: 'Equipment LC - eBMR',
      icon: ApprovalforEquipmentLCeBMR,
      url: 'dispensing-list?type=granulation'
    }

    // {
    //   //title: 'Log Books',
    //   icon: truck,
    //   url: 'log-books'
    // }
  ];

  const QALeadcards = [
    {
      //title: 'Create Dispense Request',
      icon: CreateDispenserequest,
      url: 'dispensing'
    },
    {
      //title: 'Missing Document',
      icon: approvalformissingdocuments,
      url: 'qc-list?qc=doc_ver_pending'
    },
    {
      //title: 'Storage Inspection Failure',
      icon: approvalforstorageinspectionfailure,
      url: 'qc-list?qc=veh_ver_pending'
    },
    {
      //title: 'Damages Reported',
      icon: approvalfordamagesreported,
      url: 'qc-list?qc=dmg_ver_pending'
    },
    {
      //title: 'Discrepency Raised',
      icon: approvalfordiscrepancyraised,
      url: 'qc-list?qc=wh_ver_pending'
    },
    {
      //title: 'LC to Dispense Material',
      icon: approvalforLCtodispensematerial,
      url: 'dispense-material-list'
    },
    {
      //title: 'Stock Reconciliation',
      icon: approvalforstockreconciliation,
      url: 'stock-reconsilation'
    },
    {
      //title: 'Wastage Material',
      icon: ApprovalforWastageMaterial,
      url: 'wastage-reconsilation'
    },
    {
      //title: user?.level == 3 ? 'Vendor/Material' : 'Add New Vendor/Material',
      icon: AddNewVendorsMaterials,
      url: 'vendor-material'
    },
    {
      // //title: 'Material based Dispensing Request',
      icon: Approvalformaterialbaseddispensing,
      url: 'dispense-qa-materials-list'
    },
    // {
    //   //title: 'Product',
    //   icon: ApprovalLCDispense,
    //   url: 'product-list'
    // },
    // {
    //   //title: 'Log Books',
    //   icon: ApprovalLCDispense,
    //   url: 'log-books'
    // },
    {
      //title: 'Material Return Request',
      icon: MaterialReturnIcon,
      url: 'material-return-request'
    },
    {
      //title: 'Code to Code Transfer',
      icon: CodetocodeIcon,
      url: 'code-to-code-list'
    },
    {
      //title: 'Equipment LC - eBMR',
      icon: ApprovalforEquipmentLCeBMR,
      url: 'dispensing-list?type=granulation'
    },
    {
      //title: 'Batch Numbering Log Book',
      icon: BatchnumberingLogbook,
      url: 'log-book-list'
    },
    {
      //title: 'eBMR - Configuration',
      icon: ApprovalforEquipmentLCeBMR,
      url: 'product-list'
    }
  ];
  const PodUsercards = [
    {
      //title: 'Create Dispense Request',
      icon: CreateDispenserequest,
      url: 'dispensing'
    },
    {
      //title: 'List of Dispensing Materials',
      icon: Listofdispensingmaterials,
      url: 'dispensing-list'
    },
    {
      //title: 'Add New Product',
      icon: AddNewProduct,
      url: 'product-list'
    },
    {
      //title: 'Execute BMR',
      icon: ExecuteBMR,
      url: 'dispensing-list?type=granulation'
    },
    {
      //title: 'Material Return Request',
      icon: MaterialReturnIcon,
      url: 'material-return-request'
    },
    {
      //title: 'Product Master',
      icon: ProductMaster,
      url: 'product-masters-list'
    },
    {
      //title: 'EBMR Master',
      icon: EBMRMaster,
      url: 'ebmr-master-list'
    },
    {
      //title: 'Move Material',
      icon: Gatepasstruck,
      url: '/landingpage'
    },
    {
      icon: ProductionLabelGeneration,
      url: `production-label-gen`
    },
    {
      icon: BMR,
      url: `requisition-slip`
    }
    // {
    //   //title: 'Log Books',
    //   icon: truck,
    //   url: 'log-books'
    // }
  ];

  const PPICCards = [
    {
      //title: 'Dispensing List',
      icon: Gatepasstruck,
      url: 'dispensing-list'
    }
    // {
    //   //title: 'Log Books',
    //   icon: truck,
    //   url: 'log-books'
    // }
  ];

  const FinanceUser = [
    {
      //title: 'Material Costing',
      icon: materialdetails,
      url: 'material-costing-list'
    },
    {
      //title: 'Invoice Details',
      icon: InvoiceDetails,
      url: 'invoice-details-list'
    }
    // {
    //   //title: 'Log Books',
    //   icon: truck,
    //   url: 'log-books'
    // }
  ];

  const BDUser = [
    {
      //title: 'Detail FG Batch Report',
      icon: Gatepasstruck,
      url: 'fg-batch-report'
    },
    {
      //title: 'COGS Report',
      icon: Gatepasstruck,
      url: 'cogs-report'
    }
    // {
    //   //title: 'Log Books',
    //   icon: truck,
    //   url: 'log-books'
    // }
  ];

  const QcCards =
    user?.level == 3
      ? [
          {
            //title: 'Batch Approval',
            icon: BatchApproval,
            url: 'batches-list?type=batches'
          },
          {
            //title: 'Sampling Material',
            icon: SampleMaterial,
            url: '/samples-list'
          },
          {
            //title: 'Test Request Forms',
            icon: TestRequestForms,
            url: '/batches-list?type=test_requests'
          },
          {
            //title: 'Create Dispense Request',
            icon: CreateDispenserequest,
            url: 'dispense-material-list'
          },
          {
            //title: 'Batch Numbering Log Book',
            icon: BatchnumberingLogbook,
            url: 'log-book-list'
          }
          // {
          //   //title: 'Log Books',
          //   icon: truck,
          //   url: 'log-books'
          // }
          // {
          //   //title: 'Code to Code Transfer',
          //   icon: truck,
          //   url: 'code-to-code-list'
          // }
        ]
      : [
          {
            //title: 'Batch Approval',
            icon: BatchApproval,
            url: 'batches-list'
          },
          {
            //title: 'Sampling Material',
            icon: SampleMaterial,
            url: '/samples-list'
          },
          {
            //title: 'Test Request Forms',
            icon: TestRequestForms,
            url: '/batches-list?type=test_requests'
          },
          {
            //title: 'Create Dispense Request',
            icon: CreateDispenserequest,
            url: 'dispense-material-list'
          },
          {
            //title: 'Batch Numbering Log Book',
            icon: BatchnumberingLogbook,
            url: 'log-book-list'
          }
          // {
          //   //title: 'Log Books',
          //   icon: truck,
          //   url: 'log-books'
          // }
        ];

  const adminCards = [
    {
      //title: 'User Management',
      icon: UserManagement,
      url: 'users-list'
    },
    {
      //title: 'Departments',
      icon: Departments,
      url: 'departments-list'
    },
    {
      //title: 'Designations',
      icon: Designations,
      url: 'designations-list'
    }
  ];
  const cardView =
    user?.role === 'user' ||
    (user?.role === 'qa_super_user' && searchParams.get('type') === 'warehouse')
      ? cards
      : user?.role === 'prod_user' ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'production')
      ? PodUsercards.concat(invoiceTrackCard)
      : (user?.role === 'qc_initiator' && user?.level !== 3) ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'quality-assurance')
      ? QAcards
      : (user?.role === 'qc_initiator' && user?.level === 3) ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'quality-assurance')
      ? QALeadcards
      : user?.role === 'qa_initiator' ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'quality-control')
      ? QcCards
      : user?.role === 'admin' ||
        (user?.role === 'qa_super_user' && searchParams.get('type') === 'admin')
      ? adminCards
      : user?.role === 'ppic_user' ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'ppic_user')
      ? PPICCards
      : user?.role === 'finance_user' ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'finance_user')
      ? FinanceUser
      : user?.role === 'bd_user' ||
        (user?.role === 'qa_super_user' &&
          searchParams.get('type') === 'bd_user')
      ? BDUser
      : user?.role === 'document_cell'
      ? dmsCard
      : user?.role === 'opt_excellence'
      ? metricsCard
      : user?.role === 'engineering'
      ? ED
      : user?.role === 'physical_audit' ||
        user?.role === 'gate_entry' ||
        user?.role === 'audit_dept' ||
        user?.role === 'site_account_dept' ||
        user?.role === 'scm_dept' ||
        user?.role === 'corporate_account_dept' ||
        user?.role === 'corporate_audit_dept' ||
        user?.role === 'store_dept'
      ? invoiceTrackCard
      : [];
  return (
    <>
      <Form form={form} layout="vertical">
        <Row gutter={24}>
          {cardView?.map((items, index) => {
            return (
              <>
                <Col
                  xs={12}
                  xl={4}
                  md={6}
                  sm={6}
                  span={6}
                  className={`${styles.cardbody1} mb-2`}
                  key={index}
                >
                  {/* <div
                    className={styles.container}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = `${items.url}`;
                    }}
                  > */}
                  {/* <div className={styles.cardleft}>
                      <div className={styles.cardimage}>
                        <Image
                          // src="https://images.pexels.com/photos/220072/pexels-photo-220072.jpeg?auto=compress&amp;cs=tinysrgb&amp;h=100%&amp;w=1260"
                          src={items.icon}
                          preview={false}
                          // style={{ height: '100% !important' }}
                          // className={
                          //   user?.role === 'qc_initiator'
                          //     ? styles.qc_stickers
                          //     : styles.stickers
                          // }
                        />
                         <Image src="https://images.pexels.com/photos/220072/pexels-photo-220072.jpeg?auto=compress&amp;cs=tinysrgb&amp;h=100%&amp;w=1260" /> 
                      </div>
                      <div className={styles.cardtext}>
                        <p>{items.title}</p>
                      </div>
                    </div> */}
                  {/* </div> */}
                  {/* <Card
                    hoverable
                    className={styles.cardbody}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = items.url;
                    }}
                  > */}
                  <Space direction="vertical">
                    <Image
                      src={items.icon}
                      preview={false}
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = `${items.url}`;
                      }}
                      style={{ cursor: 'pointer' }}
                      className={
                        user?.role === 'qc_initiator'
                          ? styles.qc_stickers
                          : styles.stickers
                      }
                    />
                    <span>{items.title}</span>
                  </Space>
                  {/* </Card> */}
                  <br />
                </Col>
              </>
            );
          })}
          {/* {cardView?.map((item, index) => {
            return (
              <Col
                xs={12}
                xl={12}
                md={12}
                sm={12}
                span={12}
                className={`${styles.cardbody1} mb-2`}
                key={index}
              >
                <Card hoverable className={styles.cardbody}>
                  <Space direction="vertical">
                    <Image
                      src={item.icon}
                      preview={false}
                      style={{ height: '200px' }}
                    />
                    <CustomButton 
                      className={styles.inwardbutton}
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = `${item.url}`;
                      }}
                    >
                      {item.name}
                    </CustomButton >
                  </Space>
                </Card>
              </Col>
            );
          })} */}
        </Row>
        {user?.role !== 'prod_user' ? (
          <Card hoverable className={styles.cardbody1}>
            <Row>
              <Col xs={24} xl={6} span={6}>
                <Space direction="vertical" size={19}>
                  <Image src={FileImage} preview={false} />

                  <span>
                    <h2 style={{ fontWeight: 'bold' }}>Our Policies</h2>
                    <p style={{ textAlign: 'justify' }}>
                      These policies establish our fundamental standards for
                      material handling and quality assurance in pharmaceutical
                      manufacturing.
                    </p>
                  </span>
                </Space>
              </Col>
              <Col xs={2} xl={2} span={2}></Col>
              <Col className="mt-3" xs={24} xl={16} span={16}>
                <Space direction="vertical" size={19}>
                  <span style={{ fontSize: '14px' }}>
                    <h4 style={{ fontWeight: 'bold' }}>
                      Material Reciept Guidelines
                    </h4>
                    {/* {user?.role === 'document_cell' */}
                    <p style={{ textAlign: 'justify' }}>
                      All incoming materials must undergo thorough inspection
                      and documentation according to cGMP requirements before
                      acceptance into inventory. Each shipment requires
                      verification of seal integrity, product identification,
                      batch numbers, and certificate of analysis against
                      purchase order specifications. Temperature-sensitive
                      materials must have continuous monitoring data reviewed
                      and documented. Delivery personnel must provide valid
                      identification, and receiving staff must photograph and
                      log any damage to containers or packaging.
                    </p>
                    {/* : 'It is a long established fact that a reader will be\
                    distracted by the readable content of a page when looking at\
                    its layout. The point of using Lorem Ipsum is that it has a\
                    more-or-less normal distribution of letters, as opposed to\
                    using Content here, content here, making it look like\
                    readable English. Many desktop publishing packages and web\
                    page editors now use Lorem Ipsum as their default model\
                    text, and a search for lorem ipsum will uncover many web\
                    sites still in their infancy. '} */}
                    <br />
                    {/* <p style={{ float: 'right', color: 'red' }}>
                      Learn More <ArrowRightOutlined />
                    </p> */}
                  </span>

                  <span style={{ fontSize: '13px' }}>
                    <h4 style={{ fontWeight: 'bold' }}>Quality policy</h4>
                    {/* {user?.role === 'document_cell' */}
                    <p style={{ textAlign: 'justify' }}>
                      Our organization is committed to maintaining the highest
                      standards of pharmaceutical manufacturing through strict
                      adherence to current Good Manufacturing Practices (cGMP)
                      and continuous quality improvement. We ensure product
                      safety, efficacy, and consistency by implementing robust
                      quality systems throughout our entire manufacturing
                      process. Every employee is responsible for quality, from
                      raw material receipt through final product release.
                    </p>
                    {/* : 'It is a long established fact that a reader will be\
                    distracted by the readable content of a page when looking at\
                    its layout. The point of using Lorem Ipsum is that it has a\
                    more-or-less normal distribution of letters, as opposed to\
                    using Content here, content here, making it look like\
                    readable English. Many desktop publishing packages and web\
                    page editors now use Lorem Ipsum as their default model\
                    text, and a search for lorem ipsum will uncover many web\
                    sites still in their infancy.'}{' '} */}
                    <br />
                    {/* <p style={{ float: 'right', color: 'red' }}>
                      Learn More <ArrowRightOutlined />
                    </p> */}
                  </span>
                </Space>
              </Col>
            </Row>
          </Card>
        ) : (
          ''
        )}
      </Form>
    </>
  );
};

export default DashBoardPage;
