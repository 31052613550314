import apiClient from 'utilities/apiClient';

export const get_metrics = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.eqp}/${url}`, payload, true);
};

export const get_metricsMasters = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.eqp}/${url}`, payload, true);
};

export const get_metrics_bacthes = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.eqp_area}/${url}`, payload, true);
};
