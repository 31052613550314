import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Space,
  Select,
  // DatePicker,
  message,
  // Image,
  // InputNumber,
  Popconfirm
} from 'antd';
import FormItem from '@/common/FormItem';
import styles from '@/common/Layout/index.module.less';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMaterialslist } from '@/features/stockCard/redux/slice';
import {
  CreateNewRequiisition,
  getAllProductNames,
  getAllProductDetails,
  getAllStageslist,
  getEquipaments,
  getAllRequisitionSlips,
  updateRequisitionSlip
} from '../../redux/slice';
import { getProductBatches } from '@/features/productionLabelGen/redux/slice';
// import truck from '@/features/qa/components/images/truck-delivery-done.gif';
import Layout from '@/common/Layout';
import { useParams } from 'react-router-dom';
import { getLoggedInUser } from 'utilities/helpers';
import DMS from '@/features/dms';
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
// import { suppliers } from '@/features/material/redux/api';
const { Option } = Select;

const CreateRequisitionPage = () => {
  const [form] = Form.useForm();
  const { productNames, productDetails } = useSelector(
    (state) => state.product
  );

  const { user } = getLoggedInUser();
  const params = useParams();
  const dispatch = useDispatch();
  // const [truckShow, setTruckShow] = useState(false);
  const [stagesList, setStagesList] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [search, setSearch] = useState('');
  // const [formatnums, setFormatnums] = useState([
  //   {
  //     format_no: '',
  //     no_copies: ''
  //   }
  // ]);
  const [formatsData, setFormatsData] = useState([
    {
      format_no: '',
      no_copies: ''
    }
  ]);
  const [allsuppliers, setAllsuppliers] = useState([]);
  const [remarks, setRemarks] = useState('');
  const [isEdit, setIsEdit] = useState(params?.id ? true : false);

  const onFinish = (values) => {
    console.log(
      formatsData,
      values,
      allsuppliers,
      values?.previous_stage_batches
    );

    const previous_batches = [];

    const batchIds = allsuppliers?.[0]?.previousStage_batchNos.map(
      (item) => item.dispense_request_id
    );
    const difference = params?.id
      ? batchIds.filter((id) => !values?.previous_stage_batches.includes(id))
      : '';
    const difference1 = params?.id
      ? values?.previous_stage_batches.filter((id) => !batchIds.includes(id))
      : '';

    const format_ids = [];
    params?.id
      ? formatsData?.map((item) => {
          format_ids.push({
            req_formatno_id: item?.id,
            format_no: item?.format_no,
            no_copies: item?.no_copies,
            is_active: item?.is_active
          });
        })
      : '';
    if (difference1.length && params?.id) {
      previous_batches.push({ dispense_request_id: difference1[0] });
    }
    params?.id
      ? allsuppliers?.[0]?.previousStage_batchNos?.map((previous_batch) => {
          if (previous_batch?.dispense_request_id === difference[0]) {
            previous_batches.push({
              dispense_request_id: previous_batch?.dispense_request_id,
              previousstage_batchno_id: previous_batch?.id,
              is_active: false
            });
          } else {
            previous_batches.push({
              dispense_request_id: previous_batch?.dispense_request_id,
              previousstage_batchno_id: previous_batch?.id,
              is_active: true
            });
          }
        })
      : values?.previous_stage_batches?.map((previous_batch) => {
          previous_batches.push({ dispense_request_id: previous_batch });
        });
    values['previousStageBatches'] = previous_batches;
    values['formatNos'] = params?.id ? format_ids : formatsData;
    values['no_of_batches'] = previous_batches?.length;
    values['req_remarks'] = remarks;
    params?.id ? (values['id'] = params?.id) : '';
    dispatch(CreateNewRequiisition(values)).then((response) => {
      if (response?.payload?.success) {
        // setTruckShow(false);
        message.success(response?.payload?.message);
        history.push(`/requisition-slip`);
      }
    });
  };

  useEffect(() => {
    handleGetAllMaterialsList();
    handleGetEquipmets();
    params.id ? handleGetAllRequisitions() : '';
  }, []);

  useEffect(() => {
    handleGetProductNames();
    handleGetStages();
    // handleGetStageProcess();
    handleGetProductLabels();
  }, []);

  const handleGetProductNames = () => {
    dispatch(getAllProductNames());
  };

  const handleGetAllRequisitions = () => {
    const payload = { id: params?.id };
    dispatch(getAllRequisitionSlips(payload)).then((response) => {
      if (response?.payload?.success) {
        setAllsuppliers(response?.payload?.data);
        const previous_ids = [];
        response?.payload?.data?.[0]?.previousStage_batchNos?.map(
          (previous) => {
            previous_ids.push(previous.dispense_request_id);
          }
        );
        form.setFieldsValue({
          is_product: response?.payload?.data?.[0]?.is_product,
          product_name: response?.payload?.data?.[0]?.product_name,
          bmr_bpr_no: response?.payload?.data?.[0]?.bmr_bpr_no,
          stage: response?.payload?.data?.[0]?.stage,
          market: response?.payload?.data?.[0]?.market,
          batch_size: response?.payload?.data?.[0]?.batch_size,
          no_of_batches: response?.payload?.data?.[0]?.no_of_batches,
          req_type: response?.payload?.data?.[0]?.req_type,
          previous_stage_batches: previous_ids,
          no_of_formats: response?.payload?.data?.[0]?.req_formatNos.length
        });
        // setFormatnums(response?.payload?.data?.[0]?.req_formatNos.length);
        setFormatsData(response?.payload?.data?.[0]?.req_formatNos);
      }
    });
  };

  const handleGetProductLabels = () => {
    const payload = { search };
    dispatch(getProductBatches(payload)).then((res) => {
      if (res.payload.success) {
        setProductsData(res.payload.data);
      }
    });
  };

  const handleGetStages = () => {
    dispatch(getAllStageslist()).then((response) => {
      if (response?.payload?.success) {
        setStagesList(response?.payload?.data);
      }
    });
  };

  const handleGetEquipmets = () => {
    dispatch(getEquipaments());
  };
  const handleGetAllMaterialsList = () => {
    const payload = {};
    dispatch(getAllMaterialslist(payload));
  };

  const handleGetProductDetails = (e) => {
    let payload = { product_type_code: e };
    dispatch(getAllProductDetails(payload));
  };

  const handleProductDetails = (e) => {
    let shelf_life = productDetails.filter(
      (data) => data.product_details === e
    );
    console.log(shelf_life);
    form.setFieldsValue({ shelf_life: shelf_life[0]?.shelf_life });
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  // const handleFormat = (e) => {
  //   setFormatnums(e);
  //   console.log(e);
  // };

  const handleUpdateReq = (data) => {
    const payload = {
      requisition_id: params.id,
      status: data.status,
      req_remarks: remarks
    };
    dispatch(updateRequisitionSlip(payload)).then((response) => {
      if (response?.payload?.success) {
        message.success(response?.payload?.message);
        history.push(`/requisition-slip`);
      }
    });
  };

  const handleDeleteFormt = (i) => {
    console.log(i);
    let filter_data = formatsData.filter((item) => item.is_active);
    let list = [...filter_data];
    if (list[i].format_no !== '') {
      list[i].is_active = false;
      setFormatsData(list);
    } else {
      list.splice(i, 1);
      setFormatsData(list);
    }

    // setFormatnums(formatsData.filter((item) => item?.is_active).length);
  };

  const handleDeleteNewFormt = (id) => {
    const list = [...formatsData];
    list.splice(id, 1);
    setFormatsData(list);
  };

  const handleaddFormt = () => {
    let list = [...formatsData];
    list.push({
      format_no: '',
      no_copies: '',
      is_active: true
    });
    setFormatsData(list);
  };

  console.log(formatsData);
  const renderFields = () => {
    const fieldElements = [];
    const filterformatsData = formatsData?.length
      ? formatsData?.filter((item) => item.is_active)
      : [];
    const filter_formats = params.id
      ? formatsData?.filter((item) => item.is_active).length
      : formatsData.length;
    console.log(filterformatsData);
    for (let i = 0; i < filter_formats; i++) {
      fieldElements.push(
        <>
          <Col xs={24} xl={12} span={12} md={24} sm={24}>
            <FormItem
              label="Format"
              rules={[
                {
                  required: false,
                  message: 'Enter Format Numbers'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                placeholder="Enter number of formats"
                onChange={(e) => handleChange(i, e, 'format_no')}
                style={{ width: '100%' }}
                min={0}
                value={filterformatsData?.[i]?.format_no || ''}
                disabled={isEdit}
              />
            </FormItem>
          </Col>
          <Col xs={24} xl={params?.id ? 8 : 10} span={12} md={24} sm={24}>
            <FormItem
              label="No.of copies"
              // name="no_of_formats"
              rules={[
                {
                  required: false,
                  message: 'Enter Format Numbers'
                }
              ]}
              className={styles.customLable}
            >
              <Input
                placeholder="Enter number of copies"
                onChange={(e) => handleChange(i, e, 'no_copies')}
                style={{ width: '100%' }}
                min={0}
                value={filterformatsData?.[i]?.no_copies || ''}
                disabled={isEdit}
              />
            </FormItem>
          </Col>
          {allsuppliers?.[0]?.status === 'Pending' ? (
            <Space>
              <Col
                xs={24}
                xl={2}
                span={12}
                md={24}
                sm={24}
                style={{ marginTop: '3%' }}
              >
                <PlusOutlined onClick={() => handleaddFormt(i)} />
              </Col>
              {params?.id ? (
                <Col
                  xs={24}
                  xl={2}
                  span={12}
                  md={24}
                  sm={24}
                  style={{ marginTop: '3%' }}
                >
                  <CloseOutlined onClick={() => handleDeleteFormt(i)} />
                </Col>
              ) : (
                <Col
                  xs={24}
                  xl={2}
                  span={12}
                  md={24}
                  sm={24}
                  style={{ marginTop: '3%' }}
                >
                  <CloseOutlined onClick={() => handleDeleteNewFormt(i)} />
                </Col>
              )}
            </Space>
          ) : (
            ''
          )}
        </>
      );
    }
    return fieldElements; // Return the array of input elements
  };

  const handleRemarks = (e) => {
    setRemarks(e.target.value);
  };
  const handleChange = (i, e, type) => {
    console.log(e.target.value);
    const list = [...formatsData];
    list[i] = {
      ...list[i],
      [type]: e.target.value,
      is_active: true
    };
    setFormatsData(list);
  };

  console.log(formatsData);
  return (
    <Layout sider={false}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Card
          title={'BMR/BPR Requisition Slip'}
          className={styles.CustomPanel}
          extra={
            params?.id &&
            user?.role == 'prod_user' &&
            user?.level === 2 &&
            allsuppliers?.[0]?.status === 'Pending' &&
            user?.role !== 'document_cell' ? (
              <Space>
                <EditOutlined onClick={() => setIsEdit(false)} />
                <Popconfirm
                  placement="topLeft"
                  title={'Are you sure you want to Cancel this Requisition?'}
                  description={
                    'Are you sure you want to Cancel this Requisition?'
                  }
                  onConfirm={(e) => {
                    e.stopPropagation();
                    handleUpdateReq({
                      requisition_id: params.id,
                      status: 'Cancelled'
                    });
                  }}
                  onCancel={(e) => {
                    e.stopPropagation();
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <CloseOutlined />
                </Popconfirm>
              </Space>
            ) : (
              ''
            )
          }
        >
          <Row gutter={24}>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Product Name"
                name="is_product"
                rules={[
                  {
                    required: false,
                    message: 'Enter Theoretical Yield/Batch Size'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Select Product Type"
                  onChange={(e) => handleGetProductDetails(e)}
                  disabled={isEdit}
                  showSearch
                >
                  {productNames.map((item, index) => {
                    return (
                      <option value={item?.product_name_type} key={index}>
                        {item?.product_name}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={24} md={24} sm={24}>
              <FormItem
                label="Product Details"
                name="product_name"
                rules={[
                  {
                    required: true,
                    message: 'Enter detail name of the Product'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Select Product Details"
                  onChange={(e) => handleProductDetails(e)}
                  disabled={isEdit}
                >
                  {productDetails?.map((item, index) => {
                    return (
                      <option value={item?.product_details} key={index}>
                        {item?.product_details}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="BMR / BPR Number"
                name="bmr_bpr_no"
                rules={[
                  {
                    required: false,
                    message: 'Enter BMR/BPR Number'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Enter BMR/BPR Number" disabled={isEdit} />
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={24} md={24} sm={24}>
              <FormItem
                label="Stage"
                name="stage"
                rules={[
                  {
                    required: true,
                    message: 'Enter detail name of the Product'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Select Stage"
                  onChange={(e) => handleProductDetails(e)}
                  disabled={isEdit}
                >
                  {stagesList.map((item, index) => {
                    return (
                      <option value={item.stage_master_name} key={index}>
                        {item.stage_master_name}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} xl={12} span={12}>
              <FormItem
                label="Market / Customer Details"
                name="market"
                rules={[
                  {
                    required: false,
                    message: 'Enter Market / Customer Details'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  placeholder="Select Market/Customer Details"
                  defaultActiveFirstOption={true}
                  filterOption={true}
                  defaultValue="USA"
                  disabled={isEdit}
                >
                  <Option value="USA">USA</Option>
                  <Option value="India">India (Domestic)</Option>
                </Select>
              </FormItem>
            </Col>

            <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Batch Size"
                name="batch_size"
                rules={[
                  {
                    required: false,
                    message: 'Enter Theoretical Yield/Batch Size'
                  }
                ]}
                className={styles.customLable}
              >
                <Input placeholder="Batch Size" disabled={isEdit} />
              </FormItem>
            </Col>
            {params.id && (
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="No.of Batches"
                  name="no_of_batches"
                  rules={[
                    {
                      required: false,
                      message: 'Enter Theoretical Yield/Batch Size'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter Theoretical Yield/Batch Size"
                    disabled
                  />
                </FormItem>
              </Col>
            )}
            <Col xs={24} xl={12} span={12}>
              <FormItem
                label="Request Type"
                name="req_type"
                rules={[
                  {
                    required: false,
                    message: 'Select Equipment Name'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  showSearch
                  placeholder="Select Request Type"
                  disabled={isEdit}
                >
                  <option value="Exibhit">Exibhit</option>
                  <option value="Placebo">Placebo</option>
                  <option value="Trial">Trial</option>
                  <option value="Commercial">Commercial</option>
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                label="Previous Batch #"
                name="previous_stage_batches"
                rules={[
                  {
                    required: true,
                    message: 'Select Product Batch'
                  }
                ]}
                className={styles.customLable}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Previous Batch"
                  showSearch
                  optionFilterProp="children"
                  //   onChange={onChange}
                  onSearch={onSearch}
                  //   disabled={params.get('id') ? dataId : data}
                  disabled={isEdit}
                >
                  {productsData?.map((category, index) => {
                    return (
                      <option value={category?.id} key={index}>
                        {category?.product_batch_number}
                      </option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>
            {/* <Col xs={24} xl={12} span={12} md={24} sm={24}>
              <FormItem
                label="Mention the format numbers"
                name="no_of_formats"
                rules={[
                  {
                    required: false,
                    message: 'Enter Format Numbers'
                  }
                ]}
                className={styles.customLable}
              >
                <InputNumber
                  placeholder="Enter number of formats"
                  onChange={(e) => handleFormat(e)}
                  style={{ width: '100%' }}
                  min={0}
                  disabled={isEdit}
                />
              </FormItem>
            </Col> */}
          </Row>
          <Row gutter={24}>
            {renderFields()}
            {params.id && user?.role !== 'document_cell' && (
              <Col xs={24} xl={12} span={12} md={24} sm={24}>
                <FormItem
                  label="Remarks"
                  // name="req_remarks"
                  rules={[
                    {
                      required: false,
                      message: 'Enter remarks'
                    }
                  ]}
                  className={styles.customLable}
                >
                  <Input
                    placeholder="Enter remarks"
                    onChange={(e) => handleRemarks(e)}
                    disabled={isEdit}
                    value={allsuppliers?.[0]?.req_remarks || remarks}
                  />
                </FormItem>
              </Col>
            )}
          </Row>
        </Card>
        <br />
        {!params.id && user?.level === 2 ? (
          <FormItem className="text-center">
            <Space>
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Create
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </Space>
          </FormItem>
        ) : params.id &&
          user?.role == 'prod_user' &&
          user?.level === 2 &&
          user?.role !== 'document_cell' &&
          allsuppliers?.[0]?.status === 'Pending' ? (
          <FormItem className="text-center">
            <Space>
              <CustomButton
                className={styles.inwardButton}
                htmlType="submit"
                type="primary"
              >
                Update
              </CustomButton>
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </Space>
          </FormItem>
        ) : user?.role !== 'document_cell' &&
          user?.level === 3 &&
          allsuppliers?.[0]?.status === 'Pending' ? (
          <FormItem className="text-center">
            <Space>
              <Popconfirm
                placement="topLeft"
                title={'Are you sure you want to Approve this Requisition?'}
                description={
                  'Are you sure you want to Approve this Requisition?'
                }
                onConfirm={(e) => {
                  e.stopPropagation();
                  handleUpdateReq({
                    requisition_id: params.id,
                    status: 'Approved'
                  });
                }}
                onCancel={(e) => {
                  e.stopPropagation();
                }}
                okText="Yes"
                cancelText="No"
              >
                <CustomButton
                  className={styles.inwardButton}
                  htmlType="submit"
                  type="primary"
                >
                  Approve
                </CustomButton>
              </Popconfirm>
              <Popconfirm
                placement="topLeft"
                title={'Are you sure you want to Reject this Requisition?'}
                description={
                  'Are you sure you want to Reject this Requisition?'
                }
                onConfirm={(e) => {
                  e.stopPropagation();
                  handleUpdateReq({
                    requisition_id: params.id,
                    status: 'Rejected'
                  });
                }}
                okText="Yes"
                cancelText="No"
              >
                <CustomButton className={styles.inwardButton} type="primary">
                  Reject
                </CustomButton>
              </Popconfirm>
            </Space>
          </FormItem>
        ) : (
          user?.role !== 'document_cell' && (
            <FormItem className="text-center">
              <CustomButton
                className={styles.inwardButton}
                type="primary"
                ghost
                onClick={() => history.back()}
              >
                Back
              </CustomButton>
            </FormItem>
          )
        )}
      </Form>
      {user?.role === 'document_cell' && <DMS ReqId={params.id} />}
    </Layout>
  );
};
export default CreateRequisitionPage;
