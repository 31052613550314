import apiClient from 'utilities/apiClient';

export const getAllInvoices = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invtracker}/${url}`, payload, true);
};

export const createTracker = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.invtracker}/${url}`,
    payload,
    true,
    'file'
  );
};

export const getCompletedInv = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.invtracker}/${url}`, payload, true);
};
