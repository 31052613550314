import apiClient from 'utilities/apiClient';

export const addProduct = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const addGranuler = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.granulation}/${url}`, payload, true);
};

export const addSifting = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.sifting}/${url}`, payload, true);
};

export const addExtraGranulation = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.extraGranulation}/${url}`,
    payload,
    true
  );
};

export const addBlending = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.blending}/${url}`, payload, true);
};

export const addSample = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.prodSampling}/${url}`, payload, true);
};

export const addYieldReconcilation = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.yieldRecon}/${url}`, payload, true);
};

export const addBlendDistribution = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.blendDistribution}/${url}`,
    payload,
    true
  );
};

export const dispense = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const getProductNames = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const getProductDetails = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const getstageDetails = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const createnewBmr = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const getuoms = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.materialList}/${url}`, payload, true);
};

export const getStages = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const getStageProcess = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const getAllEquipments = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const getAllWeighingMaterials = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.productWeighing}/${url}`,
    payload,
    true
  );
};

export const createGross = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.productWeighing}/${url}`,
    payload,
    true
  );
};

export const getAllProcess = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.bomProdUser}/${url}`, payload, true);
};

export const getSelectedMaterials = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dispense}/${url}`, payload, true);
};

export const createbom = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.bomProdUser}/${url}`, payload, true);
};

export const getallboms = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.bomProdUser}/${url}`, payload, true);
};

export const getallparams = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.bomProdUser}/${url}`, payload, true);
};

export const getallaccessories = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.bomProdUser}/${url}`, payload, true);
};

export const createbomInstructions = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.bomProdUser}/${url}`, payload, true);
};

export const createbmrExe = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.bmrExecution}/${url}`, payload, true);
};

export const getallexeboms = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.bmrExecution}/${url}`, payload, true);
};

export const print_bmr = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.print}/${url}`, payload, true);
};

export const create_fbb = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.bmrExecution}/${url}`, payload, true);
};

export const get_fbb = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.bmrExecution}/${url}`, payload, true);
};
export const update_mat = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const delete_params = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const update_seq = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.product}/${url}`, payload, true);
};

export const add_end_date_time = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.bmrExecution}/${url}`,
    payload,
    true
  );
};

export const download_ebmr_users = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.bmrExecution}/${url}`, payload, true);
};

export const stopProcess = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.bmrExecution}/${url}`,
    payload,
    true
  );
};

export const getlcEquips = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.lcBmr}/${url}`, payload, true);
};

export const getlcCurrentProd = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.lcBmr}/${url}`, payload, true);
};

export const createClearenceforProd = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.lcBmr}/${url}`, payload, true);
};

export const getlcList = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.lcBmr}/${url}`, payload, true);
};

export const createClearenceforQA = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.lcBmr}/${url}`, payload, true);
};

export const getMasterProdiucts = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.productMaster}/${url}`, payload, true);
};

export const createMasterProduct = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.productMaster}/${url}`,
    payload,
    true
  );
};

export const getAllProcessGroups = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.productMaster}/${url}`, payload, true);
};

export const createEbmrMaster = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.productMaster}/${url}`,
    payload,
    true
  );
};

export const getExecutionData = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.exe}/${url}`, payload, true);
};

export const getRequisitionData = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.requisition}/${url}`, payload, true);
};

export const createRequisitionData = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.requisition}/${url}`, payload, true);
};
